import {action, makeAutoObservable, observable} from "mobx";


export class MusicStore {
    get musicIsPlaying(): boolean {
        return this._musicIsPlaying;
    }

    setMusicIsPlaying(value: boolean) {
        this._musicIsPlaying = value;
    }
    get audioMode(): "on" | "off" | "streamer" {
        return this._audioMode;
    }
    get musicVolume(): number {
        return this._musicVolume;
    }

    playAudio = (audio:HTMLAudioElement) =>{   
        audio.play().catch(() => {})
    };

    @action
    setMusicVolume(value: number) {
        if(this._musicVolume !== value){
            this._musicVolume = value
            if(this._currentSong) {
                this._currentSong.removeEventListener("ended", this.onEnd)
                let audio = new Audio(this._currentSong.src)
                this._currentSong.pause()
                audio.currentTime = this._currentSong.currentTime
                audio.volume = this._musicVolume / 100
                audio.onended = this.onEnd
                this.setCurrentSong(audio)
                this.playAudio(this._currentSong)
            }
        }
    }

    get uiVolume(): number {
        return this._uiVolume;
    }

    @action
    setUiVolume(value: number) {
        this._uiVolume = value;
    }
    private _currentSong?: HTMLMediaElement
    private _audioMode: 'on' | 'off' | 'streamer' = 'off'
    private _musicHasStarted: boolean = false
    private _lastSongPath?: string
    private _musicVolume: number = 1
    private _uiVolume: number = 1
    private _musicIsPlaying: boolean = false

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    @action
    getNewSong() {
        let newSongPath = 'music/' + musicFiles[this.getRandomNumber(musicFiles.length - 1)]
        while(newSongPath === this._lastSongPath){
            newSongPath ='music/' + musicFiles[this.getRandomNumber(musicFiles.length - 1)]
        }
        let audio = new Audio(newSongPath)
        this._lastSongPath = newSongPath
        this.setCurrentSong(audio)
        if(this._currentSong){
            this._currentSong.addEventListener("ended", this.onEnd);
            this._currentSong.onpause = () => {this.setMusicIsPlaying(false)}
            this._currentSong.onplaying = () => {this.setMusicIsPlaying(true)}
            this._currentSong.volume = this.musicVolume/100
            this.playAudio(this._currentSong)
        }
    }

    @action
    beginMusic() {
        if ((this._audioMode === 'on') && navigator.userActivation.hasBeenActive && !this._musicHasStarted) {
            this.setMusicHasStarted(true)
            this.getNewSong()
        }
    }


    @action
    onEnd() {
        this.getNewSong()
    }



    @action
    triggerButtonHoverAudio() {
        if (this._audioMode !== 'off') {
            try{
                const buttonHoverAudio = new Audio('sfx/button-hover.mp3')
                buttonHoverAudio.volume = this.uiVolume/100
                this.playAudio(buttonHoverAudio)
            } catch {

            }


        }
    }

    @action
    triggerButtonClickAudio() {
        if (this._audioMode !== 'off') {
            const buttonClickAudio = new Audio('sfx/button-click.mp3')
            buttonClickAudio.volume = this.uiVolume/100
            this.playAudio(buttonClickAudio)
        }
    }

    @action
    triggerPlayerJoinAudio() {
        if (this._audioMode !== 'off') {
            const buttonClickAudio = new Audio('sfx/button-click.mp3')
            buttonClickAudio.volume = 0.2
            this.playAudio(buttonClickAudio)
        }
    }

    private getRandomNumber(max: number) {
        return Math.floor(Math.random() * max)
    }

    @action
    setCurrentSong = (audioElement: HTMLMediaElement) => {
        this._currentSong = audioElement
    }

    setMusicHasStarted(value: boolean) {
        this._musicHasStarted = value;
    }

    setAudioMode(value: "on" | "off" | "streamer") {
        this._audioMode = value;
        if(value === "on") {
            this.beginMusic()
        } else {
            this._currentSong?.pause()
            this.setMusicHasStarted(false)
        }
    }

    @observable
    get currentSong() {
        return this._currentSong
    }

}
export const musicFiles = [
    'deuces.mp3', 'hepcats.mp3', 'quirkydog.mp3', 'sugarzone.mp3', 'bouncehouse.mp3', 'aurea.mp3', 'ultralounge.mp3',
]