import React, {useEffect} from 'react'
import './index.scss'
import { observer } from 'mobx-react'
import rootStore from "../../../stores/RootStore";
import {useNavigate} from "react-router";

export const GuestWaitingPage = observer(() => {

    const {sessionStore, movieStore} = rootStore
    const navigate = useNavigate()

    useEffect(() => {
        if(movieStore.movie){
            navigate('/game/active')
        }
    }, [movieStore.movie])

    useEffect(() => {
        const unloadCallback = (event: any) => {
            event.preventDefault();
            event.returnValue = "If you refresh or leave this session, you cannot rejoin.";
            return "If you refresh or leave this session, you cannot rejoin.";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
    
    return (
        <div className={"guest-waiting-container"}>
            <div className="card-header guest-waiting">
                <h2 className="guest-waiting-header">
                    Waiting for game
                </h2>
            </div>
            <div className="room-code-container">
                <div className="player-count-section">
                    <h4>Players: {sessionStore.sessionPlayers?.length ?? 0}</h4>
                </div>
            </div>
        </div>
    )
})