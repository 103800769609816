import {action, makeAutoObservable, observable} from "mobx";
import rootStore, {RootStore} from "../RootStore";

export class UiStore {

    @observable
    get mobileWindowFocus(): "info" | "main" | "poster" {
        return this._mobileWindowFocus;
    }

    @action
    setMobileWindowFocus(value: "info" | "main" | "poster") {
        this._mobileWindowFocus = value;
    }
    get disclaimerConfirmed(): boolean {
        return this._disclaimerConfirmed;
    }

    setDisclaimerConfirmed(value: boolean) {
        this._disclaimerConfirmed = value;
    }
    get posterIsExpanded(): boolean {
        return this._posterIsExpanded;
    }

    setPosterIsExpanded(value: boolean) {
        this._posterIsExpanded = value;
    }

    private _rootStore: RootStore;
    private _isDraggableLocked: boolean = false
    private _posterIsExpanded: boolean = false
    private _disclaimerConfirmed: boolean = false
    private _mobileWindowFocus: 'info' | 'main' | 'poster' = 'main'
    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this._rootStore = rootStore
        this._isDraggableLocked = false
    }
    get isDraggableLocked(): boolean {
        return this._isDraggableLocked;
    }

    setIsDraggableLocked(value: boolean) {
        this._isDraggableLocked = value;
    }

    scoreColor(): string {
        const score = this._rootStore.gameStore.playerGuessValue
        if(score){
            if(score >= 8){
                return 'green'
            } else if(score >= 5){
                return 'yellow'
            } else {
                return 'red'
            }
        } else {
            return 'green'
        }
    }
}
export default UiStore;