import React from 'react';
import './index.scss'
import {observer} from "mobx-react";
import rootStore from "../../../../../stores/RootStore";
import {clsx} from "clsx";

export interface LeaderboardPlayerProps {
    playerName: string
    playerScore?: number | null | undefined
    id?: string
}

export const LeaderboardPlayer = observer((props: LeaderboardPlayerProps) => {
    const {playerName, playerScore, id} = props
    const {sessionStore, sessionConnectionStore, gameStore} = rootStore

    const hasPlayerGuessed = () => {
        if((gameStore.isSolo() || gameStore.isDle()) && gameStore.playerScoreThisRound){
            return true
        }
        if(gameStore.playerGuesses && gameStore.playerGuesses.length > 0){
            return gameStore.playerGuesses?.some((guess) => {
                if(guess.session_connection_id === id){
                    return true
                }
            })
        }
        return false

    }

    return (
        <div key={playerName + playerScore?.toString()} className={clsx("leaderboard-player-container", hasPlayerGuessed() && 'guessed')}>
            <div className={"leaderboard-player"}>
                <div className={"leaderboard-player-name"}>
                    {playerName}
                </div>
                <div className={"leaderboard-player-score"}>
                    {playerScore ?? 0}

                </div>
            </div>
        </div>
    )
})