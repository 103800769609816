import './index.scss'
import React, {useState} from 'react'
import {clsx} from "clsx";
import {PlayerGuessData} from "../../../../../API.ts";
import {observer} from "mobx-react";


export interface GuessResultItemProps {
    guessData: PlayerGuessData
    name: string
    guessValue: number
    avatarPath: string
    id?: string
}

export const GuessResultItem = observer((props: GuessResultItemProps) => {
    const {guessData, guessValue, avatarPath, name, id} = props
    const [isExpanded, setIsExpanded] = useState(false)


    const getHintsUsedCount = () => {
        let hintsUsed = 0
        if(guessData.poster_unlocked){
            hintsUsed++
        }
        if(guessData.summary_unlocked){
            hintsUsed++
        }
        if(guessData.review_unlocked){
            hintsUsed++
        }
        return hintsUsed
    }

    return (
        <button onClick={() => setIsExpanded(!isExpanded)} className={clsx("player-guess-result-item", id, isExpanded && 'expanded')}>
            <div className={"player-guess-result-header"}>
                <div className={"player-guess-result-header-left-content"}>
                    <img src={avatarPath ?? '/profile0.jpg'} alt={"profile image"}/>
                    <strong>{name}</strong>
                </div>
                <div className={"player-guess-result-header-right-content"}>
                    <h6>Hints used ({getHintsUsedCount()}/3)</h6>
                    <h6>Actors Guessed: {guessData.correct_actor_guess_names?.length ?? 0}</h6>
                    {isExpanded ? <button onClick={() => setIsExpanded(false)}>
                        <img src={"chevron_up.svg"} alt={"chevron_down"}/>
                    </button> :
                    <button onClick={() => setIsExpanded(true)}>
                        <img src={"/chevron_down.svg"} alt={"dropdown-button"}/>
                    </button>}
                </div>
                <div className={"player-guess-score-container"}>
                    <div className={"player-guess-result-header-score"}>
                        <h2>{guessValue}</h2>
                    </div>
                </div>

            </div>
            {isExpanded && <div className={"player-guess-result-content"}>
                <div className={"player-guess-result-left-content"}>
                    <div className={clsx("player-guess-result-row", guessData.poster_unlocked && 'red')}>
                        <p>Poster:</p>{guessData.poster_unlocked ?
                        <img src={"/minus.svg"} alt={""}/> :
                        <img src={"/plus.svg"} alt={""}/>}
                    </div>
                    <div className={clsx("player-guess-result-row", guessData.summary_unlocked && 'red')}>
                        <p>Summary:</p>{guessData.summary_unlocked ?
                        <img src={"/minus.svg"} alt={""}/> :
                        <img src={"/plus.svg"} alt={""}/>}
                    </div>
                    <div className={clsx("player-guess-result-row", guessData.review_unlocked && 'red')}>
                        <p>Review:</p>{guessData.review_unlocked ?
                        <img src={"/minus.svg"} alt={""}/> :
                        <img src={"/plus.svg"} alt={""}/>}
                    </div>

                </div>
                <div className={"player-guess-result-content-right"}>
                    {guessData.correct_actor_guess_names?.map((name) => {
                        return <p>{name}</p>
                    })}
                </div>
            </div>}
        </button>
    )
})