export function imageExists(image_url: string){

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 400;

}

export const PATH_TO_TMDB_URL = (path?: string): string => "https://media.themoviedb.org/t/p/original" + path

export const delay = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export const convertObjectToArray = (obj: { [key: string]: any }): any[] => {
    return Object.keys(obj).map(key => obj[key]);
}
