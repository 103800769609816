import React from 'react'
import './index.scss'
import rootStore from "../../../../stores/RootStore";
import {GuessResultItem} from "./GuessResultItem";
import {observer} from "mobx-react";

export const PostRoundColumn = observer(() => {

    const {sessionStore,
        playerStore,
        advancedGameWindowStore,
        gameStore} = rootStore

    return (
        <>
            <div className="post-round-column">
                <div className={"post-round-column-header"}>
                    <h2>Results</h2>
                </div>
                <div className={"post-round-column-content"}>
                    <div className={"player-guess-results-container"}>
                        {(gameStore.isHost() || gameStore.isGuest()) ?
                                gameStore.playerGuesses?.map((guess) => {
                                let avatarPath: string | undefined | null = undefined
                                sessionStore.sessionPlayers?.map((connection) => {
                                    if(connection?.id === guess.session_connection_id){
                                        avatarPath = connection?.avatar_url
                                    }
                                })

                                    return guess.guess_data && <GuessResultItem guessData={guess.guess_data}
                                                                name={guess.player_name}
                                                                guessValue={guess.guess_value}
                                                                avatarPath={avatarPath ?? 'profile0.png'}/>
                                 }) : <GuessResultItem guessData={advancedGameWindowStore.getGuessDataObject()}
                                                       name={playerStore.player?.name ?? 'guest'}
                                                       guessValue={gameStore.playerGuessValue}
                                                       avatarPath={playerStore.player?.avatar_url ?? 'profile0.png'}/>
                        }
                </div>
            </div>
        </div>
    </>
    )
})