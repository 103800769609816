import React from 'react';
import './index.scss'
import {GameButton} from "../../components/buttons/GameButton";
import {useNavigate} from "react-router";

export const Sources = () => {
    const navigate = useNavigate()
    const onClick = (event: any) => {
        event.preventDefault()
        navigate('/game')
    }
    return (
        <div className={"sources-scene"}>
            <div className={'sources-card'}>
                <div className={'sources-card-body'}>
                    <div className={"music-sources"}>
                        <h3> Music </h3>
                        <p className="music-credit">"Hep Cats" Kevin MacLeod (incompetech.com)<br/>
                            Licensed under Creative Commons: By Attribution 3.0 License<br/>
                            http://creativecommons.org/licenses/by/3.0/</p>
                        <p className="music-credit">"Deuces" Kevin MacLeod (incompetech.com)<br/>
                            Licensed under Creative Commons: By Attribution 4.0 License<br/>
                            http://creativecommons.org/licenses/by/4.0/</p>
                        <p className="music-credit">"Quirky Dog" Kevin MacLeod (incompetech.com)
                            Licensed under Creative Commons: By Attribution 4.0 License
                            http://creativecommons.org/licenses/by/4.0/</p>
                    </div>
                    <div className={'tech-sources'}>
                        <h3> Tech & Data </h3>
                        <h4><a href={'https://www.themoviedb.org/'}>TMDB API</a></h4>
                    </div>
                </div>
            </div>
            <GameButton onClick={onClick}>
                Return to Megacritic
            </GameButton>
        </div>
    )
}