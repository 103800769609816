import {GameInfoWindow} from "../../../kit/components/gameInfoWindow";
import rootStore from "../../../stores/RootStore";
import {ErrorPopup} from "../../../kit/components/popups/ErrorPopup";
import {clsx} from "clsx";
import {LoadRoller} from "../../../kit/components/loading";
import React, {PropsWithChildren, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {Outlet, useLocation, useNavigate} from "react-router";
import './index.scss'
import {MobileGameNav} from "../../../kit/scenes/GamePage/MobileGameNav";

export const GameWindowWrapper = observer((props: PropsWithChildren) => {
    const [isFirstRender, setIsFirstRender] = useState(true)
    const {gameStore, movieStore, cookieStore, musicStore, playerStore, authStore, uiStore} = rootStore
    const {pathname} = useLocation()

    useLayoutEffect(() => {
        gameStore.setIsLoading(true)
        onRender().then(() => {
            gameStore.setIsLoading(false)
        })
    }, []);

    useEffect(()=>{
        if(pathname !== "/game/active" && pathname !== "/game/guest-waiting" && pathname !== "/game/host-waiting"){
        gameStore.resetCurrentRoundMovieData()
        gameStore.setSoloPlayerScoreThisSession(0)
        gameStore.setGameState("inactive")
    }
        setInterval(() => {
            authStore.getCurrentUser().catch()
        }, 600000)
    },[pathname])


    const getExistingCookiesForUser = () => {
        cookieStore.loadUserData().then(() => {})
    }

    const onRender = async () => {
        if(isFirstRender) {
            getExistingCookiesForUser()
            await authStore.getCurrentUser().catch()
            setIsFirstRender(false)
            gameStore.resetPlayerGuesses()
        }
    }
    if(navigator.userActivation.hasBeenActive) {
        if(!musicStore.musicIsPlaying && !cookieStore?.userData?.settings?.isAudioMuted){
            musicStore.beginMusic()
        }
    }
    const getScoreColor = () => {
        if(gameStore.playerGuessValue >= 8.0) {
            return 'green'
        } else if (gameStore.playerGuessValue  >= 5.0 ){
            return 'yellow'
        } else if (gameStore.playerGuessValue < 5.0) {
            return 'red';
        } else {
            return 'gray';
        }
    }
    return (
        <div className="App">
            <GameInfoWindow/>
            <ErrorPopup/>
            <div className="home-root">
                <div className={clsx("game-container",
                    (movieStore.movie ||
                        gameStore.gameState === 'solo' ||
                        gameStore.gameState === 'host' ||
                        gameStore.gameState === 'guest')
                    && 'has-movie',
                    uiStore.posterIsExpanded && 'poster-expanded',
                    uiStore.mobileWindowFocus === 'main' && 'mobile-active')}>

                    <div
                        className={clsx("score-color-stripe", movieStore.movie && 'has-movie', getScoreColor())}/>
                    {
                        !gameStore.isLoading ?
                            <div className="game-body">
                                <div className={clsx("game-card", movieStore.movie && 'has-movie')}>
                                    <Outlet/>
                                </div>
                            </div> : <LoadRoller/>
                    }
                </div>
                <MobileGameNav/>
            </div>
        </div>
    )
    })
