import {action, makeAutoObservable, observable} from "mobx";
import {
    createNewPlayerGuess,
} from "../../api/graphql";
import {
    PlayerGuess,
} from "../../API";
import {RootStore} from "../RootStore";

export class GameStore {
    @observable
    get gameState(): "dle" | "solo" | "host" | "guest" | 'inactive' {
        return this._gameState;
    }

    @action
    setGameState(value: "dle" | "solo" | "host" | "guest" | 'inactive') {
        this._gameState = value;
    }
    @observable
    get isOffline(): boolean {
        return this._isOffline;
    }
    @action
    setIsOffline(value: boolean) {
        this._isOffline = value;
    }
    get playerGuesses(): PlayerGuess[] | undefined {
        return this._playerGuesses;
    }
    @action
    setPlayerGuesses(value: PlayerGuess[] | undefined) {
        this._playerGuesses = value;
    }
    @observable
    get playerGuessThisRound(): PlayerGuess | undefined {
        return this._playerGuessThisRound;
    }
    @action
    setPlayerGuessThisRound(value?: PlayerGuess) {
        this._playerGuessThisRound = value;
    }

    // State variables
    _playerGuessValue: number = 5.0;
    private _isOffline: boolean = true
    _winningGuess?: PlayerGuess = undefined;
    private _playerGuesses?: PlayerGuess[] | undefined
    private _playerGuessThisRound?: PlayerGuess = undefined;
    private _playerScoreThisRound?: number = undefined
    private _soloPlayerScoreThisSession: number = 0
    private _isLoading: boolean = false;
    private _rootStore: RootStore
    private _gameState: 'dle' | 'solo' | 'host' | 'guest' | 'inactive' = 'inactive'

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    // Asynchronous actions

    @action
    async hostStartNewRound() {
        //create new round
        this.setIsLoading(true)
        this.resetCurrentRoundMovieData()
        this._rootStore.advancedGameWindowStore.setCorrectGuesses([])
        this._rootStore.sessionStore.setAwaitingConnections(false)
        if(this._rootStore.sessionStore.sessionPlayers?.length && this._rootStore.sessionStore.sessionPlayers?.length > 1){
            await this._rootStore.movieStore.getMovie(false, true);
            if(this._rootStore.movieStore.movie && this._rootStore.sessionStore.publicSession?.id){
                await this._rootStore.sessionRoundStore.createNewRound()
            }
        }
        this.setIsLoading(false)
    }

    @action
    async hostEndSession() {
        this.setIsLoading(true)
        await this._rootStore.sessionStore.deleteCurrentSession().catch()
        this._rootStore.sessionStore.resetCurrentSessionData()
        this._rootStore.sessionRoundStore.resetStore()
        this.resetCurrentRoundMovieData()
        this._rootStore.sessionConnectionStore.resetStore()
        this.setIsLoading(false)
    }

    @action
    async onNewPlayerGuess(guess: PlayerGuess) {
        if(guess.public_session_id === this._rootStore.sessionStore.publicSession?.id){
            let newGuessList = this.playerGuesses ? this.playerGuesses : []
            newGuessList?.push(guess)
            this.setPlayerGuesses(newGuessList)
        }
    }

    @action
    resetPlayerGuess() {
        this.setPlayerGuessThisRound(undefined)
    }

    @action
    async submitCurrentGuess() {
        this.setIsLoading(true)
        const finalScore = await this.calculateFinalScore()
        if(this.gameState === 'solo' || this.gameState === "dle" && finalScore){
            this.setPlayerScoreThisRound(+finalScore)
            this.setSoloPlayerScoreThisSession(this.soloPlayerScoreThisSession + (+finalScore))

            if(this.gameState === 'dle'){
                let newUserStorageData = this._rootStore.cookieStore.userData
                if(newUserStorageData.dleData){
                    newUserStorageData.dleData.hasGuessBeenSubmitted = true
                    newUserStorageData.dleData.submittedGuessValue = this.playerGuessValue
                    this._rootStore.cookieStore.setUserData(newUserStorageData)
                }
            }
        } else if (this._rootStore.sessionRoundStore.currentRound?.id &&
            this._rootStore.sessionStore.publicSession?.id &&
            this._rootStore.playerStore.player?.name) {

            const newGuess = await createNewPlayerGuess(this._rootStore.authStore.accessToken?.toString(),
                this._rootStore.sessionStore.publicSession?.id,
                this._rootStore.playerStore.player.name,
                this.playerGuessValue,
                this._rootStore.advancedGameWindowStore.getGuessDataObject(),
                this._rootStore.sessionConnectionStore.sessionConnection?.id)
            this.setPlayerGuessThisRound(newGuess)
            this.setPlayerScoreThisRound(finalScore)
            await this._rootStore.sessionConnectionStore.addNewScore(finalScore)
        }
        this.setIsLoading(false)
    }

    @action
    async guestOnNewRound() {
        this.setIsLoading(true)
        await this.resetCurrentRoundMovieData()
        this.resetPlayerGuess()
        this.setPlayerGuesses([])
        await this._rootStore.movieStore.getMovie()
        this.setIsLoading(false)
    }
    @action
    async onStartSoloGame() {
        this.setIsLoading(true)
        await this._rootStore.movieStore.getMovie()
        this.setIsLoading(false)
    }

    @action
    async guestLeaveSession() {
        this.setIsLoading(true)
        if(this.gameState === 'dle' || this.gameState === 'solo') {
            this._rootStore.movieStore.resetCurrentMovieData()
            this.setPlayerScoreThisRound(undefined)
            this.setSoloPlayerScoreThisSession(0)
        } else {
            await this._rootStore.sessionConnectionStore.closeCurrentSessionConnection().catch()
            this._rootStore.sessionStore.resetCurrentSessionData()
            this._rootStore.sessionRoundStore.resetStore()
            await this.resetCurrentRoundMovieData()
            this._rootStore.sessionConnectionStore.resetStore()
        }
        this.setIsLoading(false)


    }
    @action
    resetCurrentRoundMovieData(){
        this._rootStore.movieStore.resetCurrentMovieData()
        this._rootStore.advancedGameWindowStore.resetStore()
        this.setWinningGuess(undefined)
        this.setPlayerScoreThisRound(undefined)
        this.setPlayerGuessThisRound(undefined)
        this.setPlayerGuesses([])
        this._rootStore.uiStore.setPosterIsExpanded(false)
    }
    get playerScoreThisRound(): number | undefined {
        return this._playerScoreThisRound;
    }

    get soloPlayerScoreThisSession(): number {
        return this._soloPlayerScoreThisSession;
    }

    @action
    setSoloPlayerScoreThisSession(value: number) {
        this._soloPlayerScoreThisSession = value;
    }

    @action
    setPlayerScoreThisRound(value?: number) {
        this._playerScoreThisRound = value;
    }

    @observable
    isSolo(){
        return this.gameState === "solo"
    }

    @observable
    isDle(){
        return this.gameState === "dle"
    }

    @observable
    isHost(){
        return this.gameState === "host"
    }

    @observable
    isGuest(){
        return this.gameState === "guest"
    }

    @observable
    isInactive(){
        return this.gameState === "inactive"
    }

    // Actions

    // Methods to modify state
    @action
    setIsLoading(value: boolean) {
        this._isLoading = value;
    }
    @action
    setPlayerGuessValue(score: number) {
        this._playerGuessValue = score;
    }
    @action
    setWinningGuess(guess?: PlayerGuess) {
        this._winningGuess = guess;
    }

    @action
    resetPlayerGuesses(){
        this.setPlayerGuesses([])
    }

    get playerGuessValue() {
        return this._playerGuessValue;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    haveAllPlayersGuessed = () => {
        return !!(this._rootStore.sessionStore.publicSession?.id &&
            this.playerGuesses?.length &&
            this._rootStore.sessionStore.sessionPlayers?.length &&
            this.playerGuesses?.length >= this._rootStore.sessionStore.sessionPlayers?.length);
    }

    private async calculateFinalScore() {
        const playerScore = this._rootStore.advancedGameWindowStore.playerScore
        const actualAnswer = +(this._rootStore.movieStore.movie?.vote_average?.toFixed(1) ?? 0)
        const submittedAnswer = +(this.playerGuessValue);
        const difference = Math.abs(submittedAnswer - actualAnswer);
        const pointsGained = 100 - ((difference * 50 > 100) ? 100 : difference*50)
        return +((playerScore + pointsGained)).toFixed(0);
    }

}
