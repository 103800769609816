import React from "react";
import * as ReactRouter from 'react-router-dom'
import {TermsOfService} from "../kit/scenes/termsOfService";
import {GenericPageWrapper} from "./wrappers";
import {Navigate} from "react-router";
import {Sources} from "../kit/scenes/Sources";
import {ErrorBoundaryPage} from "../kit/scenes/ErrorBoundary"
import {SettingsPage} from "../kit/scenes/SettingsPage";
import {LoginPage} from "../kit/scenes/LoginPage";
import {HostWaitingPage} from "../kit/scenes/HostWaitingPage";
import {JoiningPage} from "../kit/scenes/JoiningPage";
import {CreatePlayerPage} from "../kit/scenes/CreatePlayerPage";
import {GuessPage} from "../kit/scenes/GamePage";
import {HostCreatePage} from "../kit/scenes/HostCreatePage";
import {GameWindowWrapper} from "./wrappers/GameWindowWrapper";
import {HomePage} from "../kit/scenes/HomePage";
import { LandingPage } from "../kit/scenes/LandingPage";
import {PrivacyPolicy} from "../kit/scenes/PrivacyPolicy";
import {GuestWaitingPage} from "../kit/scenes/GuestWaitingPage";
import {SoloCreatePage} from "../kit/scenes/SoloCreatePage";
import {ProfilePage} from "../kit/scenes/ProfilePage";
export const MegacriticRouter = () => {
    return ReactRouter.createBrowserRouter(
    [
            {
                path: "*",
                element: <GenericPageWrapper/>,
                errorElement:  <ErrorBoundaryPage/>,
                children:
                    [
                        {
                            path: "game",
                            element: <GameWindowWrapper/>,
                            children: [
                                {path: 'settings', element: <SettingsPage/>},
                                {path: 'host-waiting', element: <HostWaitingPage/>},
                                {path: 'joining', element: <JoiningPage/>},
                                {path: 'host-create', element: <HostCreatePage/>},
                                {path: 'create-player', element: <CreatePlayerPage/>},
                                {path: 'guest-waiting', element: <GuestWaitingPage/>},
                                {path: 'active', element: <GuessPage/>},
                                {path: 'home', element: <HomePage/>},
                                {path: 'solo-create', element: <SoloCreatePage/>},
                                {path: 'profile', element: <ProfilePage/>},
                                {path: "login", element: <LoginPage/>},
                                {path: '*', element: <Navigate replace={true} to={"/game/home"}/>},

                            ]
                        },
                        {path: "terms-of-service", element: <TermsOfService />},
                        {path: "privacy-policy", element: <PrivacyPolicy/>},
                        {path: "sources", element: <Sources/>},
                        {path: "landing", element: <LandingPage/>},
                        {path: "*",element: <Navigate replace={true} to={"/landing"}/>},
                    ]
                },
            ]
        )
}