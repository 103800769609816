import React from 'react';
import './index.scss'
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {useNavigate} from "react-router";
export const TermsOfService = () => {
    const navigate = useNavigate()

    const onClick = (event: any) => {
        event.preventDefault()
        navigate('/game')
    }
    return (
        <>
            <div className={"terms-of-service-scene"}>
                <div className={"terms-of-service-card"}>
                    <div className={"terms-of-service-text-container"}>
                        <div className={"terms-of-service-text-body"}>
                            <title>Terms of Service for Megacritic</title>
                            <h1>Terms of Service for Megacritic</h1>
                                Here are the terms you should know before using our website hosted at <a href="https://mega-critic.com">https://mega-critic.com</a>
                            <ol>
                                Users are permitted to utilize basic
                                frontend code provided by the application for the
                                purpose of interacting with and accessing the
                                features and functionalities of the service.
                                However, users are expressly prohibited from
                                accessing any data or APIs outside of
                                what is made available or accessible
                                directly within the application interface.
                                Any attempt to circumvent this restriction and gain unauthorized access
                                to private data or APIs is strictly prohibited and will result in
                                Megacritic revoking your rights to use the service.
                            </ol>
                            <p>By using Megacritic, you agree to comply with these terms. If you have any questions, email:</p>
                            <p>gabriel.edwards@mega-critic.com</p>
                        </div>
                    </div>
                </div>
                <GameButton onClick={onClick}>
                    Return to Megacritic
                </GameButton>
            </div>
        </>
    )
}