import { GraphQLSubscription } from "aws-amplify/api";
import rootStore, {RootStore} from "../RootStore";
import {action, makeAutoObservable, observable} from "mobx";

export class SubscriptionStore {
    @observable
    get currentSessionSubs(): GraphQLSubscription<any>[] {
        return this._currentSessionSubs;
    }

    @action
    setCurrentSessionSubs(value: GraphQLSubscription<any>[]) {
        this._currentSessionSubs = value;
    }

    @action
    addSubscription(value: GraphQLSubscription<any>) {
        this._currentSessionSubs.push(value)
    }

    resetCurrentSessionSubs() {
        if(this.currentSessionSubs && this.currentSessionSubs.length > 0) {
            this.currentSessionSubs.forEach(sub => sub.unsubscribe())
        }
    }

    private _rootStore: RootStore

    private _currentSessionSubs: GraphQLSubscription<any>[] = []
    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }
}