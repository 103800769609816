import Filter from "bad-words";

export const validateUserInputString = (input: any) => {
    // Check if input is a string and its length is less than or equal to 50 characters
    if(input === '') return true
    if (typeof input !== 'string' || input.length > 50) {
        return false;
    }

    // Regular expression to match only English alphabet letters
    const englishAlphabetRegex = /^[a-zA-Z0-9]+\s?[a-zA-Z0-9]*$/;

    // Check if input contains only English alphabet letters
    if (!englishAlphabetRegex.test(input)) {
        return false;
    }

    // If all conditions are met, return true
    return true;
}

export const isValidEmail = (email: string): boolean => {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

export const validateUserInputStringNoProfanity = (input: string) => {
    const filter = new Filter()
    return !filter.isProfane(input)
}