import {makeAutoObservable} from "mobx";
import {RootStore} from "../RootStore";
import {MovieDle} from "../../API.ts";
import {TmdbMovie, TmdbReview} from "../../types/tmdb/movie.tsx";

export class DleGameStore {

    private _rootStore: RootStore;
    private _submittedGuess?: number

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this._rootStore = rootStore
    }

    resetDleStoreData() {
        this.setSubmittedGuess(undefined)
    }

    get submittedGuess() {
        return this._submittedGuess
    }

    setSubmittedGuess(submittedGuess?: number) {
        let newUserData = this._rootStore.cookieStore.userData
        if(newUserData?.dleData) {
            newUserData.dleData.hasGuessBeenSubmitted = true
            newUserData.dleData.submittedGuessValue = submittedGuess
            newUserData.dleData.dleMovieId = this._rootStore.movieStore.movie?.id
        }

        this._rootStore.cookieStore.setDleSubmittedGuessValue(submittedGuess ?? 0)
        this._rootStore.cookieStore.setDleHasGuessBeenSubmitted()
        this._rootStore.cookieStore.setDleMovieId(this._rootStore.movieStore.movie?.id)

        this._submittedGuess = submittedGuess
    }
}
export default DleGameStore