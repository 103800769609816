import React, {ReactElement} from 'react'
import rootStore from "../../../stores/RootStore";
import {clsx} from "clsx";



export interface GameButtonProps extends React.PropsWithChildren {
    type?: "submit" | "reset" | "button" | undefined
    disabled?: boolean
    onClick?: (event: any) => void
    className?: string
    soundOff?: boolean
    children?: ReactElement | undefined | string
}
export const GameButton = (props: GameButtonProps) => {
    const {onClick,
        soundOff,
        disabled,
        type,
        className,
        children} = props
    const {musicStore} = rootStore
    const onClickCallback = (event: any) => {
        musicStore.triggerButtonClickAudio()
        onClick?.(event)
    }

    const onHoverCallback = (event: any) => {
        event.preventDefault()
        !soundOff && musicStore.triggerButtonHoverAudio()
    }
        return (
        <button name={className}
                onClick={onClickCallback}
                className={clsx(className ? className : '', 'game-button', disabled && 'disabled')}
                disabled={disabled}
                onMouseEnter={onHoverCallback}
                type={type ? type : 'button'}>
            {children}
        </button>
    )
}