import React from 'react'
import rootStore from "../../../../stores/RootStore";
import './index.scss'
import {LeaderboardPlayer} from "./leaderboardPlayer";
import {observer} from "mobx-react";

export const LeaderboardColumn = observer(() => {
    const { playerStore, gameStore, sessionStore} = rootStore

    const playerIdentifier = playerStore.player?.name ?
        playerStore.player?.name : 'guest'

    return (
        <div className="leaderboard-column-container">
            <div className={"leaderboard-column"}>
                <div className={"leaderboard-column-header"}>
                    <h2 className={"leaderboard-column-header-text"}>
                        Players
                    </h2>
                </div>
                <div className={"leaderboard-column-body"}>
                    { (gameStore.gameState !== 'solo' && gameStore.gameState !== 'dle') ?
                        sessionStore.sessionPlayers?.map((sessionPlayer) => {
                            return (
                                <LeaderboardPlayer playerName={sessionPlayer?.player_name ?? playerIdentifier}
                                                   playerScore={sessionPlayer?.player_score}
                                                   id={sessionPlayer?.id}
                                />
                            )}) :
                        <LeaderboardPlayer playerName={playerIdentifier} playerScore={gameStore.soloPlayerScoreThisSession}/>
                    }
                </div>

            </div>
        </div>
    )
})