import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// @ts-ignore
import reportWebVitals from './reportWebVitals.js';
import { Amplify } from 'aws-amplify';
// @ts-ignore
import config from './aws-exports.js';
import MegacriticGame from "./App";
import {GoogleOAuthProvider} from "@react-oauth/google";

Amplify.configure(config);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="311206982521-90gndnm7qkip4vfn1hgbv14fu4bcc3au.apps.googleusercontent.com">
      <>
        <MegacriticGame />
      </>
    </GoogleOAuthProvider>
);

// @ts-ignore
module.hot?.accept()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
