import React, {useEffect, useLayoutEffect} from 'react'
import './index.scss'
import {observer} from "mobx-react";
import {RainbowWave} from "../../components/styles/rainbowWave";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {Navigate, useLocation, useNavigate} from "react-router";
import rootStore from "../../../stores/RootStore";
import advancedGameWindowStore from "../../../stores/AdvancedGameWindowStore";
import {runInAction} from "mobx";
import {Player} from "../../../models";

export const HomePage = observer(() => {
    const {advancedGameWindowStore,
        gameStore,
        playerStore,
        authStore,
        movieStore,
        sessionStore,
        sessionRoundStore,
        sessionConnectionStore,
        uiStore} = rootStore
    const navigate = useNavigate()

    const onClickDles = async (event: any) => {
        gameStore.resetPlayerGuesses()
        sessionStore.resetCurrentSessionData()
        sessionRoundStore.resetStore()
        sessionConnectionStore.resetStore()
        uiStore.setPosterIsExpanded(false)
        event.preventDefault()
        gameStore.setIsLoading(true)
        gameStore.setGameState("dle")
        await movieStore.getMovie(true)
        navigate('/game/active')
    }

    useLayoutEffect(() => {
        if(!authStore.currentUser){
            playerStore.setPlayer({name: "guest", score: 0} as Player)
            gameStore.setIsOffline(true)
        }

    }, [])
    if(!playerStore.player?.name) {
        authStore.getCurrentUser().then(() => {
            if(!playerStore.player?.name) {
                navigate('/game/login')
            }
        })
    }

    return (
        <>
            {<div className="home-page-body">
                <div className="card-header">
                    <RainbowWave content={"MegaCritic"}/>
                </div>
                <div className="button-container">
                    <>
                        {
                            false &&
                            <GameButton className={"dle-button"}
                                              onClick={onClickDles}>
                                    Movie of the Day
                            </GameButton>
                        }
                    </>
                    <GameButton className={"start-button"} onClick={() => navigate('/game/solo-create')}>Solo Game</GameButton>
                    <GameButton className={"join-button"}
                                onClick={() => navigate('/game/joining')}
                                disabled={gameStore.isOffline}>
                        Join a Game
                    </GameButton>

                    <GameButton className={"host-button"} onClick={() => {navigate('/game/host-create')}} disabled={gameStore.isOffline}>
                        Host a Game
                    </GameButton>

                    <GameButton className={"settings-button"} onClick={() => navigate('/game/settings')}>Settings</GameButton>

                </div>
            </div>}

        </>
    )
})
