import React, {useEffect, useLayoutEffect, useState} from 'react'
import {MovieFilterForm} from "../../components/movieFilterform";
import {useNavigate} from "react-router";
import rootStore from "../../../stores/RootStore";
import {observer} from "mobx-react";
import './index.scss'
import {Player} from "../../../models";


export const SoloCreatePage = observer(() => {
    const navigate = useNavigate()
    const {gameStore, sessionStore, authStore, playerStore, sessionRoundStore, sessionConnectionStore, uiStore, movieStore} = rootStore
    const onStartSolo = async () => {
        gameStore.resetPlayerGuesses()
        sessionStore.resetCurrentSessionData()
        sessionRoundStore.resetStore()
        sessionConnectionStore.resetStore()
        uiStore.setPosterIsExpanded(false)
        await movieStore.getMovie()
        gameStore.setGameState('solo')
        navigate('/game/active')
    }

    useEffect(() => {
        if(authStore.accessToken){
            playerStore.setPlayer({name: "guest", score: 0} as Player)
            gameStore.setIsOffline(true)
        }

    }, [])

    return (
        <div className="solo-create-page-container">
            <MovieFilterForm onSubmit={onStartSolo}/>
        </div>
    )
})
