import React, {PropsWithChildren, useState} from 'react';
import {observer} from "mobx-react";
import {clsx} from "clsx";
import './index.scss'
import rootStore from "../../../../../stores/RootStore";

export interface UnlockItemComponent extends PropsWithChildren {
    isUnlocked?: boolean,
    itemName: string,
    formattedItemName: string,
    pointCost: number,
    onClick: (unlockTypeName: string, pointCost: number) => void
}

export const UnlockItemComponent = observer((props: UnlockItemComponent) => {
    const {gameStore} = rootStore
    const {children,
        isUnlocked,
        itemName, formattedItemName,
        pointCost,
        onClick} = props

    const itemCostText = gameStore.playerScoreThisRound ? "Free!" : pointCost + " points"

    const lockedItemText = "Unlock " + formattedItemName + " - " + itemCostText
    const unlockedItemText = formattedItemName + " - Unlocked"
    const [isExpanded, setIsExpanded] = useState(false)

    const onUnlockItem = (event: any) => {
        event.preventDefault();
        if(!isUnlocked){
            onClick(itemName, pointCost)
            setIsExpanded(true)
        } else{
            setIsExpanded(!isExpanded)
        }
    }

    return (
        <>
            <button className={clsx("item-container", isUnlocked && "unlocked", isExpanded && 'expanded')} type={'button'} onClick={onUnlockItem}>
                <div className={"item-trigger-container"}>
                    <div className={"item-header"}>
                        <div>{isUnlocked ? unlockedItemText : lockedItemText }</div>
                    </div>
                    {isUnlocked && children && <div className={clsx("item-content-container", isExpanded && 'expanded')}>
                        <div className={"item-content"}>
                            {children}
                        </div>

                    </div>}
                </div>
            </button>
        </>
    )
})