import React from 'react';
import rootStore from "../../../../stores/RootStore";
import {GameButton} from "../../../components/buttons/GameButton.tsx";
import {observer} from "mobx-react";
import {useNavigate} from "react-router";
import dleGameStore from "../../../../stores/DleGameStore";
export const GameOverSection = observer(() => {
    const {gameStore,
        movieStore,
        sessionConnectionStore,
        sessionStore,
        sessionRoundStore,
        cookieStore} = rootStore
    const navigate = useNavigate()
    const getGuessMessage = () => {
        const newScore = +(gameStore.playerGuessValue).toFixed(1)
        if(gameStore.gameState === 'dle'){
            return 'You guessed ' + newScore
        }
        if(rootStore.sessionConnectionStore.isGuest){
            return 'Waiting for host... You scored: '+ gameStore.playerScoreThisRound
        }
        if(rootStore.sessionConnectionStore.isHost || rootStore.sessionConnectionStore.isGuest){
            return 'Waiting for other players... You scored: '+ gameStore.playerScoreThisRound
        }

        if(!!gameStore.playerScoreThisRound) {
            return "You scored: " + gameStore.playerScoreThisRound
        }
    }

    const onClick = async (event: any) => gameStore.gameState === 'host' ? await hostPlayAgain(event) : soloPlayAgain(event)

    const onLeaveSession = async (event: any)=> {
        event.preventDefault()
        gameStore.setIsLoading(true)
        const gameState = gameStore.gameState
        if(!!sessionStore.publicSession){
            if(gameState === 'host'){
                await gameStore.hostEndSession()
                gameStore.setGameState("inactive")
                navigate('/game/home')
                gameStore.setIsLoading(false)
            } else {
                await gameStore.guestLeaveSession()
                gameStore.setGameState("inactive")
                navigate('game/home')
                gameStore.setIsLoading(false)
            }
        } else {
            soloLeaveSession().then(() => {
                gameStore.setGameState("inactive")
                gameStore.setIsLoading(false)
                navigate('/game/home')})
        }

    }


    const hostPlayAgain = async (event: any) => {
        event.preventDefault()
        gameStore.setIsLoading(true)
        await gameStore.hostStartNewRound()
    }

    const soloPlayAgain = async (event: any) => {
        event.preventDefault()
        gameStore.setIsLoading(true)
        await gameStore.resetCurrentRoundMovieData()
        await movieStore.getMovie()
        gameStore.setIsLoading(false)
    }

    const canHostPlayAgain = () => {
        const currentRoundNumber = sessionRoundStore.currentRound?.round_number
        const finalRoundNumber = sessionStore.publicSession?.settings?.max_rounds
        if(sessionConnectionStore.isHost && currentRoundNumber && finalRoundNumber && gameStore.haveAllPlayersGuessed()){
            return currentRoundNumber < finalRoundNumber
        }
    }

    const soloLeaveSession = async () => {
        await gameStore.resetCurrentRoundMovieData()
        if(gameStore.gameState === 'dle'){
            cookieStore.saveUserDataToLocalStorage()
        }
    }

   return (
       <>
           <div className="game-over-container">
               <div className="results-message">
                   <h4>{"The actual answer is... "}</h4>
                   <div className={'actual-score-container'}>
                       <h1>{movieStore.movie?.vote_average?.toFixed(1)}</h1>
                   </div>
                   <h4>{getGuessMessage()}</h4>
                   <div className="game-over-button-container">
                       {
                           (gameStore.gameState === 'solo' || canHostPlayAgain()) &&
                               <GameButton onClick={onClick} className="play-again-button">
                                   Play Again!
                               </GameButton>
                       }
                       <GameButton onClick={onLeaveSession}>
                           {sessionConnectionStore.isHost ? "End Session" : "Back to Menu"}
                       </GameButton>
                    </div>
               </div>
           </div>
       </>
   )
})