import {action, makeAutoObservable} from "mobx";
import {RootStore} from "../RootStore";

export class PopupStore {
    private _rootStore: RootStore;
    private _errorPopup: Popup

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get errorPopup(): Popup | undefined {
        return this._errorPopup
    }

    setErrorPopup(message: string, msTimeout: number = 3000) {
        this._errorPopup = {isOpen: true, message: message}
        setInterval(() => {
            this.closeErrorPopup()
        }, msTimeout)
    }

    @action
    closeErrorPopup() {
        this._errorPopup.isOpen = false
    }

}