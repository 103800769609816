import rootStore, {RootStore} from "../RootStore";
import {action, makeAutoObservable} from "mobx";
import {AuthUser, JWT, fetchAuthSession, getCurrentUser, fetchUserAttributes, signOut} from "aws-amplify/auth";
import {isValidEmail} from "../../validation";

export class AuthStore {
    get accessToken(): JWT | undefined {
        return this._accessToken;
    }

    setAccessToken(value: JWT | undefined) {
        this._accessToken = value;
    }

    get currentUser(): AuthUser | undefined {
        return this._currentUser;
    }

    @action
    setCurrentUser(value: AuthUser | undefined) {
        this._currentUser = value;
    }

    private _rootStore: RootStore;
    private _accessToken?: JWT | undefined

    private _currentUser: AuthUser | undefined = undefined;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    async getCurrentUser(): Promise<void> {
        await getCurrentUser().then(async (user: AuthUser) => {
            this._currentUser = user
            await fetchAuthSession().catch()
            if(this.currentUser?.userId) {
                await fetchUserAttributes().then(async (userAttributes) => {
                    if (userAttributes.email && isValidEmail(userAttributes.email)) {
                        if(this.currentUser?.userId){
                            await rootStore.playerStore.loadPlayerFromUserId(this.currentUser.userId).catch(() => {
                                // Sign out because user probably needs to create a player.
                                signOut()
                            })
                            if (this._rootStore.playerStore.player?.name) {
                                this._rootStore.gameStore.setIsOffline(false)
                            }
                        }

                    }
                }).catch()
            }
        }).catch((error) => {})
    }
}
export default AuthStore