import React from 'react';
import './App.scss';
import {MegacriticRouter} from "./router";
import {RouterProvider} from "react-router-dom";
import {observer} from "mobx-react";


export const MegacriticGame = observer(() => {
  return (
      <div className="root">
            <RouterProvider router={MegacriticRouter()}/>
      </div>
  )
})

export default MegacriticGame;
