import React, {useEffect, useState} from 'react'
import rootStore from "../../../stores/RootStore";
import {observer} from "mobx-react";
import './index.scss'
import {validateUserInputStringNoProfanity} from "../../../validation";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {useNavigate} from "react-router";
import {signOut} from "aws-amplify/auth";

const avatar_file_names = [
    "profile0.jpg",
    "profile1.jpg",
    "profile2.jpg",
    "profile3.jpg",
    "profile4.png",
    "profile5.jpg"
]

export const ProfilePage = observer(() => {
    const {playerStore, authStore, gameStore} = rootStore
    const navigate = useNavigate()
    const [editingUsername, setEditingUsername] = useState(false)
    const [username, setUsername] = useState({value: playerStore.player?.name ?? ""})
    const [imagePathIndex, setImagePath] =
        useState({value: playerStore.player?.avatar_url?.substring(7, 8) ?
                +playerStore.player?.avatar_url?.substring(7, 8) : 0})

    const clickUsernameEdit = () => {
        setEditingUsername(!editingUsername)
    }

    const onChangeUsername = (event: any) => {
        event.preventDefault()
        if(event.target.value){
            setUsername({value: event.target.value})
        } else{
            setUsername({value: ""})
        }
    }

    const changesHaveBeenMade = () => {
        if(playerStore.player?.name !== username.value){
            return true
        } else if(!playerStore.player?.avatar_url && imagePathIndex.value !== 0) {
            return true
        }
        return !!(playerStore.player?.avatar_url &&
            imagePathIndex.value >= 0 &&
            avatar_file_names[imagePathIndex.value] !== playerStore.player?.avatar_url);
    }

    const isNameValid = () => {
        if(!username.value){
            return false
        }
        if (username.value.length < 3 || username.value.length > 20) {
            return false;
        }
        const alphanumericRegex = /^[a-zA-Z0-9_]+$/;
        return (alphanumericRegex.test(username.value) && validateUserInputStringNoProfanity(username.value));
    }

    const onSubmit = async (event: any) => {
        event.preventDefault()
        if(isNameValid()){
            await playerStore.updatePlayerName(username.value)
            setEditingUsername(false)
        }
        if(avatar_file_names[imagePathIndex.value] && avatar_file_names[imagePathIndex.value] !== playerStore.player?.avatar_url){
            await playerStore.updatePlayerImage(avatar_file_names[imagePathIndex.value])
        }
    }

    const onReset = () => {
        setUsername({value: playerStore.player?.name ?? ""})
        setImagePath({value: playerStore.player?.avatar_url?.substring(7, 8) ?
                +playerStore.player?.avatar_url?.substring(7, 8) : 0})
    }

    const onClickLeftChevron = (event: any) => {
        event.preventDefault()
        if(imagePathIndex.value > 0){
            setImagePath({value: imagePathIndex.value - 1})
        }
    }
    const onClickRightChevron = (event: any) => {
        event.preventDefault()
        if(imagePathIndex.value < avatar_file_names.length - 1){
            setImagePath({value: imagePathIndex.value + 1})
        }
    }

    const handleLogout = async() => {
        try {
            await signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
        navigate('/game/login')
    }

    useEffect(() => {
        if(gameStore.isOffline){
            navigate("/game/home")
        }
    }, [])



    return (
        <div className="profile-scene">
            <div className="profile-scene-content">
                <div className="profile-scene-content-header">
                    <div className={"card-header"}> Profile</div>
                    <div className="profile-picture-row">
                        <button onClick={onClickLeftChevron}><img src={"chevron_left.svg"}/></button>
                        <img alt={"profile-picture"} src={avatar_file_names[imagePathIndex.value]} onLoad={() => gameStore.setIsLoading(false)}/>
                        <button onClick={onClickRightChevron}><img src={"chevron_right.svg"}/></button>
                    </div>
                    <div className="username-content-row">
                        <button type={"button"} onClick={clickUsernameEdit}>
                            <img src="/edit.svg" alt={"edit-username"}/>
                        </button>
                        <span>
                            <strong>
                                Username:
                            </strong>
                            {!editingUsername ?
                                <span>{username.value}</span> :
                                    <input
                                        className="username-input"
                                        value={username.value}
                                        onChange={onChangeUsername}
                                    />
                            }
                        </span>
                    </div>
                </div>
                     <div className={"submit-profile-edit-buttons"}>

                         {changesHaveBeenMade() ? <>
                            <GameButton className={"profile-edit-cancel"} onClick={onReset}>
                                Cancel
                            </GameButton>
                            <GameButton onClick={onSubmit} className={"profile-edit-submit"}>
                                Save
                            </GameButton>
                        </> : <>
                                <GameButton onClick={() => navigate('/game/home')} className={"back-home"}>
                                    Return to menu
                                </GameButton>
                                <GameButton className={"logout-button red"} onClick={handleLogout}>
                                    Logout
                                </GameButton>
                         </>}

                    </div>

            </div>
        </div>
    )
})