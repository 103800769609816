import React from 'react';
import { useNavigate, useRouteError } from "react-router"
import { GameButton } from '../../components/buttons/GameButton';


export const ErrorBoundaryPage = () =>{

    const navigate = useNavigate();
    const error = useRouteError()
    const isError = error instanceof Error
    return (
            <div className='game-container'>
                <div className={"score-color-stripe red"}/>
                <h1>Something went wrong</h1>
                {<pre>{isError ? error.message:JSON.stringify(error)}</pre>}
                <GameButton onClick={()=> navigate(0)}>
                    Return Home
                </GameButton>
            </div>
    )
}
