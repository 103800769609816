import React, {useState, useEffect, useLayoutEffect} from 'react';
import rootStore from '../../../stores/RootStore';
import {observer} from "mobx-react";
import './index.scss';

export const GameTimer = observer(() => {

    useLayoutEffect(() => {
        rootStore.sessionRoundStore.setTimeLeftCurrentRound(rootStore.sessionStore.publicSession?.settings?.time_limit)
        rootStore.sessionRoundStore.startRoundCountDown()
    }, []);

    useEffect(() => {
        if (!!rootStore.gameStore.playerGuessThisRound) {
        rootStore.sessionRoundStore.setTimeLeftCurrentRound(undefined)
    }}, [])

    return (
        <>

                {(rootStore.sessionRoundStore.timeLeftCurrentRound !== undefined &&
                        !rootStore.gameStore.playerGuessThisRound &&
                        rootStore.sessionRoundStore.timeLeftCurrentRound !== 0) &&
                    <div className={"game-timer-container"}>
                        <div className={"game-timer-content"}>
                            <h1>Time left: {rootStore.sessionRoundStore.timeLeftCurrentRound}</h1>
                        </div>
                    </div>
                }

        </>
    )
})