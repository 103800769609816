
import rootStore, {RootStore} from "../RootStore";
import {action, makeAutoObservable} from "mobx";
import {TmdbMovie, TmdbReview} from "../../types/tmdb/movie.tsx";
import {getMovieById, getReviewByReviewId, requestRandomMovie} from "../../api/tmdb.tsx";
import {getDleForTheDay} from "../../api/graphql.tsx";
const imageUrl = "https://media.themoviedb.org/t/p/original"

export class MovieStore {
    private _rootStore: RootStore
    private _movie?: TmdbMovie | undefined
    private _movieGenreFilter?: string | undefined
    private _minReleaseYear?: number | undefined
    private _maxReleaseYear?: number | undefined

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    getTmdbImageUrlFromPathString(pathString: string){
        return imageUrl + pathString
    }

    get movie(): TmdbMovie | undefined {
        return this._movie;
    }

    @action
    async getMovie(isDle?: boolean, hostStartingNewRound?: boolean){
        this.resetCurrentMovieData();
        this._rootStore.gameStore.setIsLoading(true)

        if (isDle) {
            this._rootStore.advancedGameWindowStore.resetStore()
            await this.getDle()
            await this._rootStore.advancedGameWindowStore.loadDleProgressFromLocalStorage()
            if(this.movie?.id){
                this._rootStore.cookieStore.setDleMovieId(this.movie?.id)
            }
        } else if (this._rootStore.sessionRoundStore.currentRound?.movie_id && !hostStartingNewRound) {
            const newMovie = await getMovieById(this._rootStore.sessionRoundStore.currentRound?.movie_id)
            if (newMovie) {
                this.setMovie(newMovie)
            }
        } else {
            this._rootStore.advancedGameWindowStore.resetStore()
            const newMovie = await requestRandomMovie(this.getFilterString());
            this.setMovie(newMovie);
        }
        this._rootStore.gameStore.setIsLoading(false)
    }

    @action
    resetCurrentMovieData(){
        this.setMovie(undefined);
    }

    private async getDle() {
        this._rootStore.gameStore.setGameState('dle')
        const movieDle =  await getDleForTheDay()
        if(movieDle) {
            if(movieDle.movie_id){
                const newMovie = await getMovieById(+movieDle.movie_id)

                const newReview = await getReviewByReviewId(movieDle.review_id)
                if (newMovie && newReview) {
                    newMovie.reviews = [newReview]
                    newMovie.vote_average = +movieDle.correct_score
                    this.setMovie({...newMovie})
                }
            }
        }
    }

    @action
    setMovie(value: TmdbMovie | undefined) {
        this._movie = value;
    }

    get maxReleaseYear(): number | undefined {
        return this._maxReleaseYear;
    }

    @action
    setMaxReleaseYear(value: number | undefined) {
        this._maxReleaseYear = value;
    }
    get minReleaseYear(): number | undefined {
        return this._minReleaseYear;
    }

    @action
    setMinReleaseYear(value: number | undefined) {
        this._minReleaseYear = value;
    }
    get movieGenreFilter(): string | undefined {
        return this._movieGenreFilter;
    }

    @action
    setMovieGenreFilter(value: string | undefined) {
        this._movieGenreFilter = value;
    }

    moviePosterImageUrl() {
        if(this._movie){
            return imageUrl + this._movie?.poster_path;
        }

    }

    private getFilterString() {
        let filterString = ""
        if (this._movieGenreFilter) {
            filterString += `&with_genres=${this._movieGenreFilter}`
        }
        if (this._minReleaseYear) {
            filterString += `&primary_release_date.gte=${+this._minReleaseYear+"-01-01"}`
        }
        if (this._maxReleaseYear) {
            filterString += `&primary_release_date.lte=${this._maxReleaseYear+"-12-31"}`
        }
        return filterString
    }

}
