import {TmdbCredditedDetails} from "../types/tmdb/movie";

export type MovieDleDataForStorage = {
    dleMovieId?: number
    hasGuessBeenSubmitted?: boolean
    submittedGuessValue?: number
    isMoviePosterUnlocked?: boolean
    isOverviewUnlocked?: boolean
    isUserReviewUnlocked?: boolean
    numberOfWrongGuessesActorOne?: number
    numberOfWrongGuessesActorTwo?: number
    numberOfWrongGuessesActorThree?: number
    numberOfWrongGuessesActorFour?: number
    correctGuesses?: TmdbCredditedDetails[]
}

export type UserSettingsDataForStorage = {
    isAudioMuted?: boolean
    musicVolume?: number
    uiVolume?: number,
    hasInteractedWithSettings: boolean,
    hasConfirmedTermsOfService: boolean
}

export type UserDataForStorage = {
    userId?: string
    firstTime?: boolean
}

export type UserLocalStorageData = {
    settings?: UserSettingsDataForStorage
    dleData?: MovieDleDataForStorage
    userData?: UserDataForStorage
}

export const USER_STORAGE_DATA = {
    settings: {
        isAudioMuted: false,
        musicVolume: .1,
        uiVolume: .1,
        hasInteractedWithSettings: false,
        hasConfirmedTermsOfService: false
    },
    dleData: {
        dleMovieId: undefined,
        hasGuessBeenSubmitted: false,
        submittedGuessValue: undefined,
        isMoviePosterUnlocked: false,
        isOverviewUnlocked: false,
        isUserReviewUnlocked: false,
        numberOfWrongGuessesActorOne: 0,
        numberOfWrongGuessesActorTwo: 0,
        numberOfWrongGuessesActorThree: 0,
        numberOfWrongGuessesActorFour: 0,
        correctActorGuesses: []
    },
    userData: {
        userId: undefined
    }
}

export const getUserCookieFromStorage = (): UserLocalStorageData => {
    const dataFromStorage = localStorage.getItem('userStorageData')
    if(!!dataFromStorage && dataFromStorage !== "undefined"){
        return JSON.parse(dataFromStorage)
    } else {
        return USER_STORAGE_DATA
    }
}