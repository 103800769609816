import React, { useState } from 'react'
import './index.scss'
import clsx from 'clsx'
import {observer} from "mobx-react";
import rootStore from "../../../../stores/RootStore";

export interface MoviePosterSectionProps {
    imageUrl: string
}

export const MoviePosterSection = observer((props: MoviePosterSectionProps) => {
    const {imageUrl} = props
    const {sessionStore, uiStore, advancedGameWindowStore} = rootStore

    const cardOnClick = () => {
        uiStore.setPosterIsExpanded(!uiStore.posterIsExpanded)
    }

    const allowImage = () => {
        return sessionStore.publicSession?.settings?.allow_image
    }

    return (
        <div className={clsx("movie-image-container", uiStore.posterIsExpanded || uiStore.mobileWindowFocus === "poster" && 'toggled', (advancedGameWindowStore.posterUnlocked || allowImage()) && 'unlocked')}>
            <button style={{}} onClick={cardOnClick}/>
            {
                advancedGameWindowStore.posterUnlocked || allowImage() ?
                    <img className={clsx('movie-poster-image',
                        (uiStore.posterIsExpanded || uiStore.mobileWindowFocus === "poster") && 'toggled',
                        uiStore.mobileWindowFocus === 'poster' && 'mobile-active')} src={imageUrl} alt='movie-img'/> :
                    <div className={"locked-image-container"}><img className='locked-image' src={"/lock.svg"} alt='movie-poster-locked'/></div> }
        </div>
            
    )
})