import {action, makeAutoObservable} from "mobx";
import {RootStore} from "../RootStore";
import {TmdbCredditedDetails} from "../../types/tmdb/movie";
import {getUserCookieFromStorage} from "../../storage";
import {updatePlayerData} from "../../api/graphql";
import {PlayerGuessData} from "../../API.ts";

export class AdvancedGameWindowStore {
    get playerScore(): number {
        return this._playerScore;
    }

    setPlayerScore(value: number) {
        this._playerScore = value;
    }
    get bannedIds(): number[] {
        return this._bannedIds;
    }

    setBannedIds(value: number[]) {
        this._bannedIds = value;
    }


    private _rootStore: RootStore;
    private _overviewUnlocked: boolean;
    private _reviewUnlocked: boolean;
    private _posterUnlocked: boolean;
    private _maxScore: number;
    private _correctGuesses: TmdbCredditedDetails[]
    private _bannedIds: number[];
    private _playerScore: number

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this._rootStore = rootStore
        this._reviewUnlocked = false
        this._overviewUnlocked = false
        this._posterUnlocked = false
        this._maxScore = 300
        this._playerScore = 100;
        this._correctGuesses = []
        this._bannedIds = []

    }

    async submitGuess(guessValue: string, isLastAttempt: boolean = false): Promise<false | TmdbCredditedDetails | undefined> {
            const movie = this._rootStore.movieStore.movie
            const completeList = movie?.cast.concat(movie?.crew ?? [])
            let isGuessCorrect = false
            let correctAnswerIdx: undefined | string = undefined

            if(completeList && completeList.length !== 0) {

                Object.entries(completeList).map((entry, key) => {
                    const fullName = entry[1].name
                    const lastName = fullName.split(' ')[-1]
        
                    if (entry[1].name.toLowerCase() === guessValue.toLowerCase()
                        || entry[1].original_name.toLowerCase() === guessValue.toLowerCase()
                            || guessValue.toLowerCase() === lastName?.toLowerCase()) {
                                isGuessCorrect = true
                                correctAnswerIdx = entry[0]
                    }
                })

                if (isGuessCorrect && correctAnswerIdx) {
                    const correctAnswer = completeList[correctAnswerIdx]
                    let isCastCrewBanned = false
                    this._bannedIds.map((id) => {
                        if(id === correctAnswer.id){
                            isCastCrewBanned = true
                        }
                    })
                    if(isCastCrewBanned){
                        return false
                    } else {
                        this._correctGuesses.push(correctAnswer)
                        this._bannedIds.push(correctAnswer.id)
                        this.setPlayerScore(this._playerScore + 25)
                        this._rootStore.cookieStore.setDleCorrectActorGuess(correctAnswer)
                        return completeList[correctAnswerIdx]
                    }

                } else {
                    if(isLastAttempt){
                        this.setMaxScore(this.maxScore - 25)
                    }
                    return false
                }}
    }

    @action
    async loadDleProgressFromLocalStorage() {
        if(!this._rootStore.cookieStore.userData){
            await this._rootStore.cookieStore.loadUserData()
        }
        if(this._rootStore.cookieStore.userData?.dleData){
            const dleData = this._rootStore.cookieStore.userData?.dleData
            this.setPlayerScore(100)
            if(this._rootStore.movieStore.movie?.id && this._rootStore.movieStore.movie?.id === dleData?.dleMovieId){
                if (dleData?.isMoviePosterUnlocked) {
                    this.setPlayerScore(this.playerScore - 25)
                    this.setPosterUnlocked(true)
                }
                if (dleData?.isOverviewUnlocked) {
                    this.setPlayerScore(this.playerScore - 25)
                    this.setOverviewUnlocked(true)
                }
                if (dleData?.isUserReviewUnlocked) {
                    this.setPlayerScore(this.playerScore - 50)
                    this.setReviewUnlocked(true)
                }
                if (dleData?.correctGuesses && dleData.correctGuesses.length > 0) {
                    this._correctGuesses = dleData.correctGuesses
                    this._correctGuesses.map((correctActor) => {
                            this.setPlayerScore(this.playerScore + 25)
                            this._bannedIds.push(correctActor.id)
                        })
                }
                if(dleData.hasGuessBeenSubmitted){
                    if(dleData?.submittedGuessValue){
                        this._rootStore.gameStore.setPlayerGuessValue(dleData.submittedGuessValue)
                        this._rootStore.gameStore.submitCurrentGuess().then(()=>{})
                    }
                }
            } else {
                await this._rootStore.cookieStore.resetDleData()
                this._rootStore.cookieStore.setDleMovieId(this._rootStore.movieStore.movie?.id)
            }
        }
    }

    getGuessDataObject(): PlayerGuessData {
        return {
            poster_unlocked: this.posterUnlocked,
            summary_unlocked: this.overviewUnlocked,
            review_unlocked: this.reviewUnlocked,
            correct_actor_guess_names: this._correctGuesses.map((actor) => {
                return actor.name
            })
        } as PlayerGuessData
    }
    get maxScore(): number {
        return this._maxScore;
    }

    @action
    setMaxScore(value: number) {
        this._maxScore = value;
    }

    get reviewUnlocked(): boolean {
        return this._reviewUnlocked;
    }

    setReviewUnlocked(value: boolean) {
        if(this._rootStore.gameStore.gameState === "dle"){
            value && this._rootStore.cookieStore.setDleReviewIsUnlocked()
        }
        this._reviewUnlocked = value;
    }
    get overviewUnlocked(): boolean {
        return this._overviewUnlocked;
    }

    setOverviewUnlocked(value: boolean) {
        if(this._rootStore.gameStore.gameState === "dle"){
            value && this._rootStore.cookieStore.setDleOverviewIsUnlocked()
        }
        this._overviewUnlocked = value;
    }
    get posterUnlocked(): boolean {
        return this._posterUnlocked;
    }

    setPosterUnlocked(value: boolean) {
        if(this._rootStore.gameStore.gameState === "dle"){
            value && this._rootStore.cookieStore.setDlePosterIsUnlocked()
        }
        this._posterUnlocked = value;
    }

    setCorrectGuesses(value: TmdbCredditedDetails[]) {
        this._correctGuesses = value;
    }
    subtractFromPlayerScore(value: number){
        this.setPlayerScore(this.playerScore - value)
    }

    @action
    resetStore(isSolo: boolean = false){
        this.setMaxAndPlayerScoreBasedOnSessionSettings(isSolo)
        this.setPosterUnlocked(false)
        this.setReviewUnlocked(false)
        this.setOverviewUnlocked(false)
        this.setCorrectGuesses([])
        this.setBannedIds([])
    }

    setMaxAndPlayerScoreBasedOnSessionSettings(isSolo: boolean) {
        if(this._rootStore.sessionStore.publicSession){
            if(this._rootStore.sessionStore.publicSession?.settings?.advanced_mode || isSolo){
                this.setMaxScore(300)
                this.setPlayerScore(100)
            } else if(this._rootStore.sessionStore.publicSession?.settings?.allow_hints){
                this.setMaxScore(200)
                this.setPlayerScore(100)
            } else {
                this.setMaxScore(100)
                this.setPlayerScore(0)
            }

        } else {
            this.setMaxScore(300)
            this.setPlayerScore(100)
        }



    }
}
export default AdvancedGameWindowStore