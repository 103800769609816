import {RootStore} from "../RootStore";
import {action, makeAutoObservable} from "mobx";
import {SessionRound} from "../../API.ts";
import {
    createNewSessionRound,
    subscribeToNewSessionRound,
    subscribeToSessionRoundUpdates,
    updateExistingPublicSession
} from "../../api/graphql.tsx";


export class SessionRoundStore {
    get timeLeftCurrentRound(): number | undefined {
        return this._timeLeftCurrentRound;
    }

    setTimeLeftCurrentRound(value: number | undefined) {
        this._timeLeftCurrentRound = value;
    }

    async incrementCountdown() {
        if(this.timeLeftCurrentRound && this.timeLeftCurrentRound !== 0){
            this.setTimeLeftCurrentRound(this.timeLeftCurrentRound - 1)
            if(this.timeLeftCurrentRound === 0){
                await this._rootStore.gameStore.submitCurrentGuess()
            }
        }
    }

    startRoundCountDown(): void {
        if(this.timeLeftCurrentRound === undefined && this._rootStore.sessionStore.publicSession?.settings.time_limit) {
            this.setTimeLeftCurrentRound(this._rootStore.sessionStore.publicSession?.settings.time_limit)
        } else if (this.timeLeftCurrentRound) {
            if(this._timerNode) clearInterval(this._timerNode)
            this._timerNode = setInterval(() => {
                if(this.timeLeftCurrentRound){
                    this.incrementCountdown().then().catch()
                }
            }, 1000)
        }
    }

    get currentRound(): SessionRound | undefined {
        return this._currentRound;
    }

    setCurrentRound(value?: SessionRound) {
        this._currentRound = value;
    }
    private _rootStore: RootStore;
    private _currentRound?: SessionRound
    private _timeLeftCurrentRound?: number
    private _timerNode: NodeJS.Timeout | undefined = undefined
    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    @action
    subscribeToNewRounds(): void{
        if(this._rootStore.sessionStore.publicSession?.id){
            subscribeToNewSessionRound(this._rootStore.authStore.accessToken?.toString(),
                this._rootStore.sessionStore.publicSession?.id,
                this.onRoundCreate)
        }
    }

    @action
    subscribeToRoundUpdates(): void {
        if(this.currentRound?.id) {
            subscribeToSessionRoundUpdates(this._rootStore.authStore.accessToken?.toString(), this.currentRound?.id, this.setCurrentRound)
        }
    }

    @action
    async createNewRound(): Promise<void> {
        if(this._rootStore.sessionStore.publicSession?.id &&  this._rootStore.movieStore.movie?.id){
            let newRoundNumber: number = 1
            if(this.currentRound?.round_number){
                newRoundNumber = this.currentRound?.round_number + 1
            }
            const newRound = await createNewSessionRound(this._rootStore.authStore.accessToken?.toString(),
                this._rootStore.movieStore.movie?.id,
                newRoundNumber,
                this._rootStore.sessionStore.publicSession?.id)
            this.setCurrentRound(newRound)
            const newPublicSession = this._rootStore.sessionStore.publicSession
            newPublicSession.current_round = newRound.id
            await updateExistingPublicSession(this._rootStore.authStore.accessToken?.toString(),newPublicSession)
        }
    }

    @action
    resetStore(): void {
        this.setCurrentRound(undefined)
    }

    @action
    async onRoundCreate(sessionRound: SessionRound){
        this._rootStore.gameStore.setIsLoading(true)
        this.setCurrentRound(sessionRound)
        await this._rootStore.gameStore.guestOnNewRound()
        this.subscribeToRoundUpdates()
        this._rootStore.gameStore.setIsLoading(false)
    }
}