import React from 'react';
import {UserCard} from "../userCard";
import './index.scss'

export const Header = () => {
    return (
        <div className="header-stripe">
            <UserCard/>
        </div>
    )
}