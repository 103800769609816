/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPlayerSessionConnection = /* GraphQL */ `query GetPlayerSessionConnection($id: ID!) {
  getPlayerSessionConnection(id: $id) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlayerSessionConnectionQueryVariables,
  APITypes.GetPlayerSessionConnectionQuery
>;
export const listPlayerSessionConnections = /* GraphQL */ `query ListPlayerSessionConnections(
  $filter: ModelPlayerSessionConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayerSessionConnections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      session_code
      player_score
      player_name
      host_approved
      host_denied
      connection_is_closed
      ttl
      public_session_id
      avatar_url
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayerSessionConnectionsQueryVariables,
  APITypes.ListPlayerSessionConnectionsQuery
>;
export const playerSessionConnectionsByPublic_session_id = /* GraphQL */ `query PlayerSessionConnectionsByPublic_session_id(
  $public_session_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPlayerSessionConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  playerSessionConnectionsByPublic_session_id(
    public_session_id: $public_session_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      session_code
      player_score
      player_name
      host_approved
      host_denied
      connection_is_closed
      ttl
      public_session_id
      avatar_url
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlayerSessionConnectionsByPublic_session_idQueryVariables,
  APITypes.PlayerSessionConnectionsByPublic_session_idQuery
>;
export const getAudienceGuess = /* GraphQL */ `query GetAudienceGuess($id: ID!) {
  getAudienceGuess(id: $id) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceGuessQueryVariables,
  APITypes.GetAudienceGuessQuery
>;
export const listAudienceGuesses = /* GraphQL */ `query ListAudienceGuesses(
  $filter: ModelAudienceGuessFilterInput
  $limit: Int
  $nextToken: String
) {
  listAudienceGuesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      value
      round_id
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAudienceGuessesQueryVariables,
  APITypes.ListAudienceGuessesQuery
>;
export const getAudienceSessionConnection = /* GraphQL */ `query GetAudienceSessionConnection($id: ID!) {
  getAudienceSessionConnection(id: $id) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceSessionConnectionQueryVariables,
  APITypes.GetAudienceSessionConnectionQuery
>;
export const listAudienceSessionConnections = /* GraphQL */ `query ListAudienceSessionConnections(
  $filter: ModelAudienceSessionConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listAudienceSessionConnections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      connection_is_closed
      roomCode
      host_approved
      ttl
      public_session_id_aud
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAudienceSessionConnectionsQueryVariables,
  APITypes.ListAudienceSessionConnectionsQuery
>;
export const audienceSessionConnectionsByPublic_session_id_aud = /* GraphQL */ `query AudienceSessionConnectionsByPublic_session_id_aud(
  $public_session_id_aud: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAudienceSessionConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  audienceSessionConnectionsByPublic_session_id_aud(
    public_session_id_aud: $public_session_id_aud
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      connection_is_closed
      roomCode
      host_approved
      ttl
      public_session_id_aud
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AudienceSessionConnectionsByPublic_session_id_audQueryVariables,
  APITypes.AudienceSessionConnectionsByPublic_session_id_audQuery
>;
export const getReservedPlayerName = /* GraphQL */ `query GetReservedPlayerName($id: ID!) {
  getReservedPlayerName(id: $id) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservedPlayerNameQueryVariables,
  APITypes.GetReservedPlayerNameQuery
>;
export const listReservedPlayerNames = /* GraphQL */ `query ListReservedPlayerNames(
  $filter: ModelReservedPlayerNameFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservedPlayerNames(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservedPlayerNamesQueryVariables,
  APITypes.ListReservedPlayerNamesQuery
>;
export const getPublicSession = /* GraphQL */ `query GetPublicSession($id: ID!) {
  getPublicSession(id: $id) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublicSessionQueryVariables,
  APITypes.GetPublicSessionQuery
>;
export const listPublicSessions = /* GraphQL */ `query ListPublicSessions(
  $filter: ModelPublicSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPublicSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      player_session_connection
      settings {
        max_players
        max_rounds
        advanced_mode
        time_limit
        allow_hints
        allow_image
        is_audience_session
        __typename
      }
      current_round
      session_is_closed
      ttl
      connected_audience {
        items {
          id
          session_code
          player_score
          player_name
          host_approved
          host_denied
          connection_is_closed
          ttl
          public_session_id
          avatar_url
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connected_players {
        items {
          id
          session_code
          player_score
          player_name
          host_approved
          host_denied
          connection_is_closed
          ttl
          public_session_id
          avatar_url
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPublicSessionsQueryVariables,
  APITypes.ListPublicSessionsQuery
>;
export const getMovieDle = /* GraphQL */ `query GetMovieDle($id: ID!) {
  getMovieDle(id: $id) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMovieDleQueryVariables,
  APITypes.GetMovieDleQuery
>;
export const listMovieDles = /* GraphQL */ `query ListMovieDles(
  $filter: ModelMovieDleFilterInput
  $limit: Int
  $nextToken: String
) {
  listMovieDles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      movie_id
      end_at
      begin_at
      ttl
      review_id
      correct_score
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMovieDlesQueryVariables,
  APITypes.ListMovieDlesQuery
>;
export const getPlayer = /* GraphQL */ `query GetPlayer($id: ID!) {
  getPlayer(id: $id) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPlayerQueryVariables, APITypes.GetPlayerQuery>;
export const listPlayers = /* GraphQL */ `query ListPlayers(
  $filter: ModelPlayerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      score
      ttl
      user_pool_id
      daily_guesses
      average_accuracy
      avatar_url
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayersQueryVariables,
  APITypes.ListPlayersQuery
>;
export const getSessionRound = /* GraphQL */ `query GetSessionRound($id: ID!) {
  getSessionRound(id: $id) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSessionRoundQueryVariables,
  APITypes.GetSessionRoundQuery
>;
export const listSessionRounds = /* GraphQL */ `query ListSessionRounds(
  $filter: ModelSessionRoundFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessionRounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      movie_id
      is_round_over
      round_number
      ttl
      public_session_id
      correct_answer
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSessionRoundsQueryVariables,
  APITypes.ListSessionRoundsQuery
>;
export const getPlayerGuess = /* GraphQL */ `query GetPlayerGuess($id: ID!) {
  getPlayerGuess(id: $id) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlayerGuessQueryVariables,
  APITypes.GetPlayerGuessQuery
>;
export const listPlayerGuesses = /* GraphQL */ `query ListPlayerGuesses(
  $filter: ModelPlayerGuessFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayerGuesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      guess_value
      player_name
      ttl
      public_session_id
      session_connection_id
      guess_data {
        poster_unlocked
        summary_unlocked
        review_unlocked
        correct_actor_guess_names
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayerGuessesQueryVariables,
  APITypes.ListPlayerGuessesQuery
>;
export const getMegacriticSession = /* GraphQL */ `query GetMegacriticSession($id: ID!) {
  getMegacriticSession(id: $id) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMegacriticSessionQueryVariables,
  APITypes.GetMegacriticSessionQuery
>;
export const listMegacriticSessions = /* GraphQL */ `query ListMegacriticSessions(
  $filter: ModelMegacriticSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listMegacriticSessions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      settings {
        max_players
        max_rounds
        advanced_mode
        time_limit
        allow_hints
        allow_image
        is_audience_session
        __typename
      }
      ttl
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMegacriticSessionsQueryVariables,
  APITypes.ListMegacriticSessionsQuery
>;
