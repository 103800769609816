import React, {useState} from 'react'
import './index.scss'
import {observer} from "mobx-react";
import {GameButton} from "../../../components/buttons/GameButton.tsx";
import {useNavigate} from "react-router";
import {SessionSettingsInput} from "../../../../API.ts";
import rootStore from "../../../../stores/RootStore";

export const CustomSessionStep = observer(() => {
    const [roomCode, setRoomCode] = useState({value: "Name your session"})
    const [maxRounds, setMaxRounds] = useState({value: 10})
    const [maxPlayers, setMaxPlayers] = useState({value: 2})
    const [advancedMode, setAdvancedMode] = useState({value: false})
    const [timeLimit, setTimeLimit] = useState({value: 0})
    const [allow_hints, setAllowHints] = useState({value: false})
    const [allow_images, setAllowImages] = useState({value: false})
    const navigate = useNavigate()
    const onFormSubmit = async (event: any) => {
        event.preventDefault()
        const sessionSettings: SessionSettingsInput = {
            max_players: maxPlayers.value,
            max_rounds: maxRounds.value,
            allow_hints: allow_hints.value,
            allow_image: allow_images.value,
            time_limit: timeLimit.value,
            advanced_mode: advancedMode.value,
            is_audience_session: false
        }
        await rootStore.sessionStore.createNewSession(roomCode.value, sessionSettings)
        navigate('/game/host-waiting')
        rootStore.gameStore.setGameState("host")
    }
    const onRoomCodeChange = (event: any) => {
        if(event.target.value === "Name your session"){
            setRoomCode({value: ""})
        } else {
            setRoomCode({value: event.target.value})
        }

    }
    const onMaxRoundsChange = (event: any) => {
        setMaxRounds({value: event.target.value})
    }
    const onMaxPlayersChange = (event: any) => {
        setMaxPlayers({value: event.target.value})
    }
    const onAdvancedModeChange = (event: any) => {
        setAdvancedMode({value: event.target.checked})
    }
    const onTimeLimitChange = (event: any) => {
        setTimeLimit({value: event.target.value})
    }
    const onAllowHintsChange = (event: any) => {
        setAllowHints({value: event.target.checked})
    }
    const onAllowImagesChange = (event: any) => {
        setAllowImages({value: event.target.checked})
    }

    const onBackHomeClick = (event: any) => {
        event.preventDefault()
        rootStore.gameStore.setGameState('inactive')
        navigate('/game/home')
    }

        return (
            <>
                <div className="card-header">
                    <h3>Configure your lobby</h3>
                </div>
            <form className="create-session-form">
                <div className="room-code-container">
                    <input className="room-code-input" value={roomCode.value} onChange={onRoomCodeChange} onFocus={onRoomCodeChange}/>
                </div>
                <div className={"create-session-inputs-container"}>
                    <div className={"max-rounds-container"}>
                        <h4>Max Rounds</h4>
                        <input className={"max-rounds-input"} defaultValue={"10"} value={maxRounds.value} onChange={onMaxRoundsChange}/>
                    </div>
                    <div className={"max-players-container"}>
                        <h4>Max Players</h4>
                        <input className={"max-players-input"} defaultValue={"4"} value={maxPlayers.value} onChange={onMaxPlayersChange}/>
                    </div>
                    <div className={"time-limit-container"}>
                        <h4>
                            Time Limit
                            {timeLimit.value > 0 ? <div>(in seconds)</div> : <div>(0 = no limit)</div>}
                        </h4>
                        <input className={"advanced-mode-input"} defaultValue={"false"} value={timeLimit.value} onChange={onTimeLimitChange}/>
                    </div>
                </div>
                <div className={"bottom-form-section"}>
                    <div className={"create-session-toggles-container"}>
                        <div className={"advanced-mode-toggle-container"}>
                            <input className={"advanced-mode-toggle"} type={"checkbox"} checked={advancedMode.value} onChange={onAdvancedModeChange}/>
                            <h4> Advanced Mode </h4>
                        </div>
                        <div className={"allow-hints-toggle-container"}>
                            <input className={"allow-hints-toggle"} type={"checkbox"} disabled={advancedMode.value} checked={allow_hints.value} onChange={onAllowHintsChange}/>
                            <h4> Allow Hints </h4>
                        </div>
                        <div className={"allow-images-toggle-container"}>
                            <input className={"allow-images-toggle"} type={"checkbox"} disabled={advancedMode.value} checked={allow_images.value} onChange={onAllowImagesChange}/>
                            <h4> Free Movie Posters</h4>
                        </div>
                    </div>
                </div>
                <div className={"bottom-form-buttons"}>
                    <GameButton className="submit-button" type={'submit'} onClick={onFormSubmit}>
                        Create Lobby
                    </GameButton>
                    <GameButton className="submit-button" onClick={onBackHomeClick}>
                        {"<- Back"}
                    </GameButton>
                </div>
            </form>
        </>
        )
})

