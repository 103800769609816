import React from 'react';
import './index.scss'
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {useNavigate} from "react-router";
export const PrivacyPolicy = () => {
    const navigate = useNavigate()

    const onClick = (event: any) => {
        event.preventDefault()
        navigate('/game')
    }
    return (
        <>
            <div className={"terms-of-service-scene"}>
                <div className={"terms-of-service-card"}>
                    <div className={"terms-of-service-text-container"}>
                        <div className={"terms-of-service-text-body"}>
                            <title>Terms of Service for Megacritic</title>
                            <h1>Privacy Policy</h1>
                                We value your privacy.
                                Megacritic does not store any personal data aside from what is necessary
                                for user authentication as described below.
                                If you choose to sign in with Google, Megacritic will request
                                your email, profile, and openid information from Google for authentication
                                purposes, but will not share this information with other users.
                                The only information shared between user sessions is your Megacritic player name
                                and other non-sensitive game mechanics/Megacritic database related information.
                            <br/>
                            <p>By using Megacritic, you are agreeing to these terms. If you have any questions, email:</p>
                            <p>gabriel.edwards@mega-critic.com</p>
                        </div>
                    </div>
                </div>
                <GameButton onClick={onClick}>
                    Return to Megacritic
                </GameButton>
            </div>
        </>
    )
}