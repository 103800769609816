
import {RootStore} from "../RootStore";
import {action, makeAutoObservable, observable} from "mobx";
import {
    getUserCookieFromStorage,
    USER_STORAGE_DATA, UserDataForStorage,
    UserLocalStorageData,
    UserSettingsDataForStorage
} from "../../storage";
import {TmdbCredditedDetails} from "../../types/tmdb/movie.tsx";

export class CookieStore {
    @observable
    get userData(): UserLocalStorageData {
        return this._userData;
    }

    @action
    setUserData(value: UserLocalStorageData) {
        this._userData = value;
        this.saveUserDataToLocalStorage()
    }


    private _rootStore: RootStore
    private _userData: UserLocalStorageData;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    @observable
    async loadUserData() {
        const loadedUserData = getUserCookieFromStorage()
        if (loadedUserData) {
            this.setUserData(loadedUserData)
        } else {
            this.setUserData(USER_STORAGE_DATA)
        }
        if(!!this._userData.settings?.uiVolume) {
            this.loadMusicDataFromStorage(this._userData.settings)
        }
    }

    @observable
    loadMusicDataFromStorage(data: UserSettingsDataForStorage) {
        if (data.hasInteractedWithSettings && !data.isAudioMuted){
            this._rootStore.musicStore.setAudioMode("on")
        } else {
            this._rootStore.musicStore.setAudioMode("off")
        }

        if(data.uiVolume) {
            this._rootStore.musicStore.setUiVolume(data.uiVolume)
        }
        if(data.musicVolume) {
            this._rootStore.musicStore.setMusicVolume(data.musicVolume)
        }
    }

    @action
    setDleMovieId(id?: number) {
        if(this._userData?.dleData?.dleMovieId){
            this._userData.dleData.dleMovieId = id
            this.saveUserDataToLocalStorage()
        }

    }

    @action
    setUserData_settings_termsOfService(value: boolean){
        let newUserStorageData = {...this._rootStore.cookieStore.userData}
        if(newUserStorageData.settings) {
            // @ts-ignore
            newUserStorageData.settings.hasConfirmedTermsOfService = value
        }
        this.setUserData(newUserStorageData)

    }

    @action
    async resetDleData() {
        if (this._userData?.dleData) {
            this._userData.dleData.dleMovieId = undefined
            this._userData.dleData.submittedGuessValue = undefined
            this._userData.dleData.correctGuesses = []
            this._userData.dleData.isOverviewUnlocked = false
            this._userData.dleData.isMoviePosterUnlocked = false
            this._userData.dleData.numberOfWrongGuessesActorOne = 0
            this._userData.dleData.isUserReviewUnlocked = false
            this._userData.dleData.numberOfWrongGuessesActorTwo = 0
            this._userData.dleData.numberOfWrongGuessesActorThree = 0
            this._userData.dleData.numberOfWrongGuessesActorFour = 0
            this._userData.dleData.hasGuessBeenSubmitted = false

        }
        this.setUserData(this._userData)
    }

    @action
    setDleHasGuessBeenSubmitted() {
        if(this._userData.dleData){
            this._userData.dleData.hasGuessBeenSubmitted = true
            this.saveUserDataToLocalStorage()
        }
    }

    @action
    setDleCorrectActorGuess(person: TmdbCredditedDetails) {
        if(this._userData.dleData){
            this._userData.dleData.correctGuesses?.push(person)
            this.saveUserDataToLocalStorage()
        }
    }

    @action
    incrementWrongActorGuessCount(formNumber: number) {
        if(this._userData.dleData){
            switch(formNumber) {
                case(1):
                    this._userData.dleData.numberOfWrongGuessesActorOne = this._userData.dleData.numberOfWrongGuessesActorOne ?
                        this._userData.dleData.numberOfWrongGuessesActorOne++ : 1
                    break
                case(2):
                    this._userData.dleData.numberOfWrongGuessesActorTwo = this._userData.dleData.numberOfWrongGuessesActorTwo ?
                        this._userData.dleData.numberOfWrongGuessesActorTwo++ : 1
                    break
                case(3):
                    this._userData.dleData.numberOfWrongGuessesActorThree = this._userData.dleData.numberOfWrongGuessesActorThree ?
                        this._userData.dleData.numberOfWrongGuessesActorThree++ : 1
                    break
                case(4):
                    this._userData.dleData.numberOfWrongGuessesActorFour = this._userData.dleData.numberOfWrongGuessesActorFour ?
                        this._userData.dleData.numberOfWrongGuessesActorFour++ : 1
                    break
            }
            this._userData.dleData.hasGuessBeenSubmitted = true
            this.saveUserDataToLocalStorage()
        }
    }

    @action
    setDleOverviewIsUnlocked() {
        if(this._userData.dleData){
            this._userData.dleData.isOverviewUnlocked = true
            this.saveUserDataToLocalStorage()
        }
    }

    @action
    setDleReviewIsUnlocked() {
        if(this._userData.dleData){
            this._userData.dleData.isUserReviewUnlocked = true
            this.saveUserDataToLocalStorage()
        }
    }

    @action
    setDlePosterIsUnlocked(){
        if(this._userData.dleData){
            this._userData.dleData.isMoviePosterUnlocked = true
            this.saveUserDataToLocalStorage()
        }
    }

    @action
    setDleSubmittedGuessValue(value: number) {
        if(this._userData.dleData){
            this._userData.dleData.submittedGuessValue = value
            this.saveUserDataToLocalStorage()
        }
    }

    saveUserDataToLocalStorage() {
        if(this._userData){
            localStorage.setItem('userStorageData' , JSON.stringify(this._userData))
        }

    }

}