import React, {useState} from 'react'
import './index.scss'
import rootStore from "../../../stores/RootStore";
import {GameButton} from "../buttons/GameButton.tsx";
import {observer} from "mobx-react";
import {useNavigate} from "react-router";


export interface MovieFilterFormProps {
    onSubmit?: (event?: any) => void
}
export const MovieFilterForm = observer((props: MovieFilterFormProps) => {
    const {movieStore} = rootStore

    const [formError, setFormError] = useState(undefined)

    const navigate = useNavigate()

    const [minReleaseYear, setMinReleaseYear] = useState({value: 1960})
    const [maxReleaseYear, setMaxReleaseYear] = useState({value: 2024})
    const [action, setAction] = useState(true)
    const [adventure, setAdventure] = useState(true)
    const [animation, setAnimation] = useState(true)
    const [comedy, setComedy] = useState(true)
    const [crime, setCrime] = useState(true)
    const [documentary, setDocumentary] = useState(true)
    const [drama, setDrama] = useState(true)
    const [family, setFamily] = useState(true)
    const [fantasy, setFantasy] = useState(true)
    const [history, setHistory] = useState(true)
    const [horror, setHorror] = useState(true)
    const [music, setMusic] = useState(true)
    const [mystery, setMystery] = useState(true)
    const [romance, setRomance] = useState(true)
    const [scienceFiction, setScienceFiction] = useState(true)
    const [tvMovie, setTvMovie] = useState(true)
    const [thriller, setThriller] = useState(true)
    const [war, setWar] = useState(true)
    const [western, setWestern] = useState(true)

    const addGenreFilterToString = (newGenre: string, filter?: string) => {
        let newFilterString: string
        if(filter?.length && filter.length > 0){
            newFilterString = filter + " | " + newGenre
        } else {
            newFilterString = newGenre
        }
        return newFilterString
    }

    const onChangeMinReleaseYear = (event: any) => {
        if(event.target.value &&
            event.target.value >= 1960 &&
            event.target.value < maxReleaseYear.value - 9) {
                setMinReleaseYear({value: +event.target.value})
        }
    }

    const onChangeMaxReleaseYear = (event: any) => {
        if(event.target.value &&
            event.target.value < 2025 &&
            event.target.value > minReleaseYear.value + 9) {
                setMaxReleaseYear({value: +event.target.value})
        }
    }

    const onSubmit = () => {
        let genreFilter = ""
        if (action) {
            genreFilter = addGenreFilterToString("28", genreFilter)
        }
        if (adventure) {
            genreFilter = addGenreFilterToString("12", genreFilter)
        }
        if (animation) {
            genreFilter = addGenreFilterToString("16", genreFilter)
        }
        if (comedy) {
            genreFilter = addGenreFilterToString("35", genreFilter)
        }
        if (crime) {
            genreFilter = addGenreFilterToString("80", genreFilter)
        }
        if (documentary) {
            genreFilter = addGenreFilterToString("99", genreFilter)
        }
        if (drama) {
            genreFilter = addGenreFilterToString("18", genreFilter)
        }
        if (family) {
            genreFilter = addGenreFilterToString("10751", genreFilter)
        }
        if (fantasy) {
            genreFilter = addGenreFilterToString("14", genreFilter)
        }
        if (history) {
            genreFilter = addGenreFilterToString("36", genreFilter)
        }
        if (horror) {
            genreFilter = addGenreFilterToString("27", genreFilter)
        }
        if (music) {
            genreFilter = addGenreFilterToString("10402", genreFilter)
        }
        if (mystery) {
            genreFilter = addGenreFilterToString("9648", genreFilter)
        }
        if (romance) {
            genreFilter = addGenreFilterToString("10749", genreFilter)
        }
        if (scienceFiction) {
            genreFilter = addGenreFilterToString("878", genreFilter)
        }
        if (tvMovie) {
            genreFilter = addGenreFilterToString("10770", genreFilter)
        }
        if (thriller) {
            genreFilter = addGenreFilterToString("53", genreFilter)
        }
        if (war) {
            genreFilter = addGenreFilterToString("10752", genreFilter)
        }
        if (western) {
            genreFilter = addGenreFilterToString("37", genreFilter)
        }
        movieStore.setMovieGenreFilter(genreFilter)
        movieStore.setMinReleaseYear(minReleaseYear.value)
        movieStore.setMaxReleaseYear(maxReleaseYear.value)
        if(props.onSubmit){
            props.onSubmit()
        }
    }

    const isFormValid = () => {
        if(minReleaseYear && (minReleaseYear.value < 1920 || (minReleaseYear.value >= maxReleaseYear.value - 9))){
            return false
        }
        if(getNumberOfSelectedGenres() <= 2){
            return false
        }
        return !(maxReleaseYear && (maxReleaseYear.value > 2024 || (maxReleaseYear < minReleaseYear)));

    }

    const onSelectAll = () => {
        setAction(true)
        setAdventure(true)
        setAnimation(true)
        setComedy(true)
        setCrime(true)
        setDocumentary(true)
        setDrama(true)
        setFamily(true)
        setFantasy(true)
        setHistory(true)
        setHorror(true)
        setMusic(true)
        setMystery(true)
        setRomance(true)
        setScienceFiction(true)
        setTvMovie(true)
        setThriller(true)
        setWar(true)
        setWestern(true)
    }

    const onSelectNone = () => {
        setAction(false)
        setAdventure(false)
        setAnimation(false)
        setComedy(false)
        setCrime(false)
        setDocumentary(false)
        setDrama(false)
        setFamily(false)
        setFantasy(false)
        setHistory(false)
        setHorror(false)
        setMusic(false)
        setMystery(false)
        setRomance(false)
        setScienceFiction(false)
        setTvMovie(false)
        setThriller(false)
        setWar(false)
        setWestern(false)
    }

    const isAllGenreSelected = () => {
        return action && adventure && animation &&
            comedy && crime && documentary && drama &&
            family && fantasy && history && horror && music &&
            mystery && romance && scienceFiction && tvMovie &&
            thriller && war && western
    }

    const isAllGenreNotSelected = () => {
        return!action &&!adventure &&!animation &&
           !comedy &&!crime &&!documentary &&!drama &&
           !family &&!fantasy &&!history &&!horror &&!music &&
           !mystery &&!romance &&!scienceFiction &&!tvMovie &&
           !thriller &&!war &&!western
    }

    const getNumberOfSelectedGenres = () => {
        let numGenres = 0
        if(action){
            numGenres++
        }
        if(adventure){
            numGenres++
        }
        if(animation){
            numGenres++
        }
        if(comedy){
            numGenres++
        }
        if(crime){
            numGenres++
        }
        if(documentary){
            numGenres++
        }
        if(drama){
            numGenres++
        }
        if(family){
            numGenres++
        }
        if(fantasy){
            numGenres++
        }
        if(history){
            numGenres++
        }
        if(horror){
            numGenres++
        }
        if(music){
            numGenres++
        }
        if(mystery){
            numGenres++
        }
        if(romance){
            numGenres++
        }
        if(scienceFiction){
            numGenres++
        }
        if(tvMovie){
            numGenres++
        }
        if(thriller){
            numGenres++
        }
        if(war){
            numGenres++
        }
        if(western){
            numGenres++
        }
        return numGenres
    }

    return (
        <>
            <div className="card-header">
                <h3>Movie Filters</h3>
            </div>
            <div className={"movie-filter-form-container"}>

                <div className={"movie-filter-form"}>
                    <div className={"date-input-section"}>
                        <div className={"min-year-input-row"}>
                            <span>Min Release Year</span><input type="number"
                                                                value={minReleaseYear.value}
                                                                onChange={onChangeMinReleaseYear}
                        />
                        </div>
                        <div className={"max-year-input-row"}>
                            <span>Max Release Year</span><input type="number" value={maxReleaseYear.value} onChange={onChangeMaxReleaseYear}/>
                        </div>
                    </div>
                    <div className={"genre-input-section"}>
                        <h4> Genre Filter</h4>
                        <div className={"check-box-button-row"}>
                            <button className={"check-all-button"} onClick={onSelectAll} disabled={isAllGenreSelected()}>Select all</button>
                            <button className={"uncheck-all-button"} onClick={onSelectNone} disabled={isAllGenreNotSelected()}>Deselect all</button>
                        </div>
                        <div className={"genre-input-columns-container"}>
                            <div className={"filter-column-left"}>
                                <div className={"input-row"}><input type={"checkbox"} checked={action} onChange={()=> {
                                    setAction(!action)
                                }}/> Action </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={adventure} onChange={()=> {
                                    setAdventure(!adventure)
                                }}/> Adventure </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={animation} onChange={()=> {
                                    setAnimation(!animation)
                                }}/> Animation </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={comedy} onChange={()=> {
                                    setComedy(!comedy)
                                }}/> Comedy </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={crime} onChange={()=> {
                                    setCrime(!crime)
                                }}/> Crime </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={documentary} onChange={()=> {
                                    setDocumentary(!documentary)
                                }}/> Documentary </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={drama} onChange={()=> {
                                    setDrama(!drama)
                                }}/> Drama </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={family} onChange={()=> {
                                    setFamily(!family)
                                }}/> Family </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={fantasy} onChange={()=> {
                                    setFantasy(!fantasy)
                                }}/> Fantasy </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={history} onChange={()=> {
                                    setHistory(!history)
                                }}/> History </div>
                            </div>
                            <div className={"filter-column-right"}>
                                <div className={"input-row"}><input type={"checkbox"} checked={horror} onChange={()=> {
                                    setHorror(!horror)
                                }}/> Horror </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={music} onChange={()=> {
                                    setMusic(!music)
                                }}/> Music </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={mystery} onChange={()=> {
                                    setMystery(!mystery)
                                }}/> Mystery </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={romance} onChange={()=> {
                                    setRomance(!romance)
                                }}/> Romance </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={scienceFiction} onChange={()=> {
                                    setScienceFiction(!scienceFiction)
                                }}/> Science Fiction </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={tvMovie} onChange={()=> {
                                    setTvMovie(!tvMovie)
                                }}/> TV Movie </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={thriller} onChange={()=> {
                                    setThriller(!thriller)
                                }}/> Thriller </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={war} onChange={()=> {
                                    setWar(!war)
                                }}/> War </div>
                                <div className={"input-row"}><input type={"checkbox"} checked={western} onChange={()=> {
                                    setWestern(!western)
                                }}/> Western </div>
                            </div>
                        </div>
                    </div>
                    <div className={"submit-button-container"}>
                        <GameButton className={"back-button"} onClick={() => navigate('/game/home')}>
                            {"<- Back"}
                        </GameButton>
                        <GameButton className={"submit-button"} type={"submit"} onClick={onSubmit} disabled={!isFormValid()}>
                            Continue
                        </GameButton>

                    </div>
                </div>
            </div>
        </>
    )
})