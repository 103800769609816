import {generateClient, GraphQLSubscription} from "aws-amplify/api";
import * as subscriptions from "../graphql/subscriptions";
import {
    createMegacriticSession,
    createPlayer,
    createPlayerGuess,
    createPlayerSessionConnection,
    createPublicSession,
    createReservedPlayerName,
    createSessionRound,
    deleteMegacriticSession,
    deletePlayerSessionConnection,
    updatePlayer,
    updatePlayerSessionConnection,
    updatePublicSession,
} from "../graphql/mutations.ts";
import {getPublicSession, listMovieDles, listPlayers
} from "../graphql/queries.ts";
import {
    MegacriticSession, MovieDle,
    Player,
    PlayerGuess, PlayerGuessData, PlayerSessionConnection, PublicSession,
    SessionRound, SessionSettings, SessionSettingsInput,
} from "../API.ts";
import { format } from "date-fns";
import {LazyPlayer} from "../models";

const client = generateClient()
/*
* D L E M O V I E
*/

export async function getDleForTheDay(): Promise<MovieDle | undefined> {
    let newDleMovie
    const currentDayString = getDateStringFromDate(new Date(new Date().setDate(new Date().getDate())))


    await client.graphql({
        query: listMovieDles,
        variables: {
            filter:{
                begin_at: {le: currentDayString},
                end_at: {gt: currentDayString}
            }
        }
    }).then((result) => {
        newDleMovie = result.data.listMovieDles.items[0]
    })
    return newDleMovie

}

function getDateStringFromDate(targetDate: Date): string {
    const day = targetDate.getDate()
    let dayString: string | undefined = undefined
    if(day < 10) {
        dayString = "0"+day
    }
    const month = targetDate.getUTCMonth() + 1
    const year = targetDate.getUTCFullYear()
    let fullDateString: string | undefined = undefined
    if(dayString){
        fullDateString = `${year}-${month}-${dayString}`
    }else {
        fullDateString = `${year}-${month}-${day}`
    }

    fullDateString = format(targetDate, "yyyy-MM-dd")
    return fullDateString
}

/*
* S E S S I O N  P L A Y E R  C O N N E C T I O N
*/

export async function createNewPlayerSessionConnection(authToken: string | undefined,
                                                       player: Player | LazyPlayer,
                                                       sessionCode: string,
                                                       isHost: boolean,
                                                       onUpdate: (value: PlayerSessionConnection, isHost: boolean) => Promise<void>,
                                                       publicSessionId: string | undefined = undefined,
                                                       manageSubscriptionCallback: (gqls: GraphQLSubscription<any>) => void) {
    const client = generateClient({authMode: "userPool", authToken })
    let newPlayerSessionConnection: PlayerSessionConnection | undefined
    await client.graphql({
        query: createPlayerSessionConnection,
        variables: {
            input: {
                session_code: sessionCode,
                player_score: 0,
                player_name: player.name ?? 'guest',
                host_approved: isHost,
                host_denied: false,
                public_session_id: publicSessionId,
                avatar_url: player.avatar_url,
                ttl: calculateTimeToLive(5)
            }
        }
    }).then(async (result) => {
        newPlayerSessionConnection = result.data.createPlayerSessionConnection
        manageSubscriptionCallback(subscribeToUpdateCurrentPlayerSessionConnection(authToken, newPlayerSessionConnection.id, onUpdate, isHost))
        onUpdate(result.data.createPlayerSessionConnection, isHost).then(() => {
        })

    })

}


export async function deleteExistingPlayerSessionConnection(authToken: string | undefined, id: string) {
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: deletePlayerSessionConnection,
        variables: {
            input: {
                id: id
            }
        },
        authToken: authToken
    }).catch()
}

export async function updateExistingPlayerSessionConnection(authToken: string | undefined,
                                                            playerSessionConnection: PlayerSessionConnection) {
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: updatePlayerSessionConnection,
        variables: {
            input: {
                id: playerSessionConnection.id,
                player_score: playerSessionConnection.player_score,
                host_approved: playerSessionConnection.host_approved,
                host_denied: playerSessionConnection.host_denied,
                connection_is_closed: !!playerSessionConnection.connection_is_closed,
                public_session_id: playerSessionConnection.public_session_id
            }
        },
        authToken: authToken
    })
}


/*
* P L A Y E R
*/

export async function createNewPlayer(authToken: string | undefined, playerName: string, user_pool_id: string): Promise<Player> {
    const client = generateClient({authMode: "userPool", authToken })
    let newPlayer: any = {}
    await client.graphql({
        query: createPlayer,
        variables: {
            input: {
                name: playerName,
                user_pool_id: user_pool_id,
                avatar_url: 'profile0.jpg',
                score: 0
            }
        },
        authToken: authToken
    }).then((result) => {
        newPlayer = result.data.createPlayer
    })
    return newPlayer
}

export async function updatePlayerData(authToken: string | undefined, player: Player){
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: updatePlayer,
        variables: {
            input: {
                id: player.id,
                score: player.score,
                name: player.name,
                avatar_url: player.avatar_url,
                ttl: calculateTimeToLive(24)
            }
        },
        authToken: authToken
    })
}

export async function getExistingPlayerByUserId(authToken: string | undefined, user_pool_id: string, callBack: (player: Player) => void): Promise<void> {
    const client = generateClient({authMode: "userPool", authToken })
    let newPlayer: Player | undefined
    await client.graphql({
        query: listPlayers,
        variables: {
            filter: {
                user_pool_id: {eq: user_pool_id}
            },
            limit: 10
        },
        authToken: authToken
    }).then((result) => {
        if(result.data.listPlayers.items[0]){
            newPlayer = result.data.listPlayers.items[0]
            callBack({...removeReadOnlyProperties(newPlayer)})
        }
    })

}

/*
* R E S E R V E D  P L A Y E R  N A M E S
 */

export async function isPlayerNameAvailable(authToken: string | undefined, name: string){
    const client = generateClient({authMode: "userPool", authToken })
    let newPlayer: Player | undefined
    await client.graphql({
        query: listPlayers,
        variables: {
            filter: {
                name: {eq: name.toLowerCase()}
            },
            limit: 1
        },
        authToken: authToken
    }).then((result) => {
        if(result.data.listPlayers.items[0]) {
            newPlayer = result.data.listPlayers.items[0]
        }
    })
    return !(!!newPlayer)
}

export async function createNewReservedPlayerName(authToken: string | undefined, name: string){
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: createReservedPlayerName,
        variables: {
            input: {
                name: name.toLowerCase()
            }
        },
        authToken: authToken
    })
}

/*
* S E S S I O N
*/
export async function createNewSession(authToken: string | undefined,
                                       session_code: string,
                                       settings: SessionSettingsInput,
                                       onSession_MUTATE_OR_CREATE: (session: MegacriticSession) => void,
                                       manageSubscriptionCallback: (gqls: GraphQLSubscription<any>) => void): Promise<void>{
    const client = generateClient({authMode: "userPool", authToken })
    let newSession: any = {}
    await client.graphql({
        query: createMegacriticSession,
        variables: {
            input: {
                code: session_code,
                settings: settings,
                ttl: calculateTimeToLive(1)
            }
        },
        authToken: authToken
    }).then(({data}) => {
        newSession = data.createMegacriticSession
    });
    onSession_MUTATE_OR_CREATE(newSession)
    manageSubscriptionCallback(subscribeToSessionUpdates(authToken, onSession_MUTATE_OR_CREATE, newSession.id))
}


export async function deleteExistingMegacriticSession(authToken: string | undefined,
                                                      session: MegacriticSession): Promise<void> {
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: deleteMegacriticSession,
        variables: {
            input: {
                id: session.id
            }
        },
        authToken: authToken
    }).then((result)=> {
        return result
    })
}


/*
* P U B L I C  S E S S I O N
*/
export async function createNewPublicSession(authToken: string | undefined,
                                             settings: SessionSettings,
                                             callBack: (s: PublicSession) => void,
                                             onNewGuessCallback: (g: PlayerGuess) => void,
                                             onConnectionUpdate: (c: PlayerSessionConnection) => void,
                                             manageSubscriptionCallback: (gqls: GraphQLSubscription<any>) => void) {
    const client = generateClient({authMode: "userPool", authToken })
    let newPublicSession: any = {}
    await client.graphql({
        query: createPublicSession,
        variables: {
            input: {
                settings: removeReadOnlyProperties(settings),
                player_session_connection: [],
                current_round: null,
                ttl: calculateTimeToLive(2)
            }
        },
        authToken: authToken
    }).then((results) => {
        callBack(results.data.createPublicSession)
        newPublicSession = results.data.createPublicSession
    })
    if(!!newPublicSession && newPublicSession.id){
        manageSubscriptionCallback(subscribeToNewGuessForCurrentPublicSession(authToken, newPublicSession.id, onNewGuessCallback))
        manageSubscriptionCallback(subscribeToUpdatePlayerSessionConnectionForCurrentSession(authToken, newPublicSession.id, onConnectionUpdate))
    }
}

export async function getExistingPublicSession(authToken: string | undefined,
                                               publicSessionId: string,
                                               sessionUpdatedCallback: (ps: PublicSession) => void,
                                               onNewSessionGuessCallback: (pg: PlayerGuess) => void,
                                               onConnectionUpdate: (psc: PlayerSessionConnection) => void,
                                               onNewRoundCallback: (sr: SessionRound) => void,
                                               onPublicSessionCloseCallback: () => void,
                                               manageSubscriptionCallback: (gqls: GraphQLSubscription<any>) => void) {
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: getPublicSession,
        variables: {
            id: publicSessionId
        },
        authToken: authToken
    }).then((results) => {
        sessionUpdatedCallback(results.data.getPublicSession as PublicSession)
        if(results.data.getPublicSession?.id){
            manageSubscriptionCallback(subscribeToNewSessionRound(authToken, results.data.getPublicSession?.id, onNewRoundCallback))
            manageSubscriptionCallback(subscribeToPublicSessionClosed(authToken, results.data.getPublicSession?.id, onPublicSessionCloseCallback))
        }

        manageSubscriptionCallback(subscribeToNewGuessForCurrentPublicSession(authToken, publicSessionId, onNewSessionGuessCallback))
        manageSubscriptionCallback(subscribeToUpdatePlayerSessionConnectionForCurrentSession(authToken, publicSessionId, onConnectionUpdate))
    })

}

export async function updateExistingPublicSession(authToken: string | undefined, publicSession: PublicSession) {
    const client = generateClient({authMode: "userPool", authToken })
    await client.graphql({
        query: updatePublicSession,
        variables: {
            input: {
                id: publicSession.id,
                player_session_connection: publicSession.player_session_connection,
                current_round: publicSession.current_round,
                session_is_closed: publicSession.session_is_closed
            },
        },
        authToken: authToken
    })
}

/*
* R O U N D
*/

export async function createNewSessionRound(authToken: string | undefined,
                                            movieId: number,
                                            roundNumber: number,
                                            publicSessionId: string) {
    const client = generateClient({authMode: "userPool", authToken })
    let newSessionRound: any = {}
     await client.graphql({
        query: createSessionRound,
        variables: {
            input: {
                movie_id: movieId,
                is_round_over: false,
                round_number: roundNumber,
                public_session_id: publicSessionId,
                ttl: calculateTimeToLive(.5)
            }
        },
         authToken: authToken
     }).then((result) => {
        newSessionRound = result.data.createSessionRound
    })
    return newSessionRound
}

/*
* G U E S S
*/

export async function createNewPlayerGuess(authToken: string | undefined,
                                           publicSessionId: string,
                                           playerName: string,
                                           guessValue: number,
                                           guessData: PlayerGuessData,
                                           currentSessionConnectionId?: string){
    const client = generateClient({authMode: "userPool", authToken })
    let newPlayerGuess: any = {}
    await client.graphql({
        query: createPlayerGuess,
        variables: {
            input: {
                public_session_id: publicSessionId,
                guess_value: guessValue,
                player_name: playerName,
                guess_data: guessData,
                session_connection_id: currentSessionConnectionId,
                ttl: calculateTimeToLive(.1)
            }
        },
        authToken: authToken
    }).then((result) => {
        newPlayerGuess = result.data.createPlayerGuess
    })
    return newPlayerGuess
}

/*
* S U B S C R I P T I O N S
 */

function subscribeToSessionUpdates(authToken: string | undefined, sessionCallback: (data: MegacriticSession) => void, sessionId: string): GraphQLSubscription<any> {
    const client = generateClient({authMode: "userPool", authToken })

    return client.graphql({
        query: subscriptions.onUpdateMegacriticSession,
        variables: {
            filter: {
                id: { eq: sessionId }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }) => {
            sessionCallback(data.onUpdateMegacriticSession)
        },
        error: (error: any) => console.warn(error)
    })
}

export function subscribeToSessionRoundUpdates(authToken: string | undefined, publicSessionId: string, roundCallback: (data: SessionRound) => void, ): GraphQLSubscription<any> {
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onUpdateSessionRound,
        variables: {
            filter: {
                public_session_id: { eq: publicSessionId }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }: any) => {
            roundCallback(data.onUpdateSessionRound)
        },
        error: (error: any) => console.warn(error)
    })
}

export function subscribeToNewSessionRound(authToken: string | undefined,
                                           publicSessionId: string,
                                           roundCallback?: (data: SessionRound) => void): GraphQLSubscription<any> {
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onCreateSessionRound,
        variables: {
            filter: {
                public_session_id: { eq: publicSessionId }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }: any ) => {
            if (roundCallback) {
                roundCallback(data.onCreateSessionRound)
            }
        },
        error: (error: any) => console.warn(error)
    })
}

function subscribeToUpdateCurrentPlayerSessionConnection(authToken: string | undefined,
                                                         sessionConnectionId: string,
                                                         callback: (data: PlayerSessionConnection,
                                                         isHost: boolean) => void,
                                                         isHost: boolean = false): GraphQLSubscription<any>{
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onUpdatePlayerSessionConnection,
        variables: {
            filter: {
                id: { eq: sessionConnectionId }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }) => {
            callback(data.onUpdatePlayerSessionConnection, isHost)
        },
        error: (error: any) => console.warn(error)
    })
}

function subscribeToUpdatePlayerSessionConnectionForCurrentSession(authToken: string | undefined,
                                                         publicSessionId: string,
                                                         callback: (data: PlayerSessionConnection) => void): GraphQLSubscription<any>{
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onUpdatePlayerSessionConnection,
        variables: {
            filter: {
                public_session_id: { eq: publicSessionId }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }) => {
            callback(data.onUpdatePlayerSessionConnection)
        },
        error: (error: any) => console.warn(error)
    })
}
function subscribeToNewGuessForCurrentPublicSession(authToken: string | undefined,
                                                    publicSessionId: string,
                                                    callback: (data: PlayerGuess) => void): GraphQLSubscription<any>{
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onCreatePlayerGuess,
        variables: {
            filter: {
                public_session_id: { eq: publicSessionId }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }) => {
            callback(data.onCreatePlayerGuess)
        },
        error: (error: any) => console.warn(error)
    })
}

export function subscribeToNewPlayerSessionConnections(authToken: string | undefined,
                                                       session_code: string,
                                                       sessionCallback: (data:PlayerSessionConnection) => void) {
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onCreatePlayerSessionConnection,
        variables: {
            filter: {
                session_code: { eq: session_code }
            }
        },
        authToken: authToken
    }).subscribe({
        next: ({ data }: any) => {
            sessionCallback(data.onCreatePlayerSessionConnection)
        },
        error: (error: any) => console.warn(error)
    });
}

export function subscribeToPublicSessionClosed(authToken: string | undefined,
                                               publicSessionId: string,
                                               callback: () => void) {
    const client = generateClient({authMode: "userPool", authToken })
    return client.graphql({
        query: subscriptions.onUpdatePublicSession,
        variables: {
            filter: {
                id: {
                    eq: publicSessionId,
                },
                session_is_closed: {
                    eq: true
                }
            }
        },
        authToken: authToken
    }).subscribe({
        next: () => {
            callback()
        },
        error: (error: any) => console.warn(error)
    })
}

function removeReadOnlyProperties(requestObject: any): any {
    const result: any = {};
    for (const key in requestObject) {
        if (!['updatedAt', 'createdAt', 'nextToken', '__typename', 'Players', 'SessionRounds', 'PlayerGuesses', 'owner', 'owners'].includes(key)) {
            // If the property is not one of the excluded properties, add it to the result
            if(requestObject[key]?.items){
                return
            } else if(!!requestObject[key]?.__typename) {
                result[key] = removeReadOnlyProperties(requestObject[key]);
            } else {
                result[key] = requestObject[key];
            }
        }
    }
    return result;
}

function calculateTimeToLive(timeToLiveFromRightNowInHours: number): number {
    return (Date.now() +(timeToLiveFromRightNowInHours * 60 * 60 * 1000))
}
