import React from 'react'
import './index.scss'
import rootStore from "../../../stores/RootStore";
import {useLocation, useNavigate} from "react-router";
import {observer} from "mobx-react";

export const UserCard = observer(() => {
    const {playerStore, authStore, gameStore} = rootStore
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const onClickUserCard = (event: any) => {
        event.preventDefault()
        pathname !== "/game/profile" ?
            navigate("/game/profile") :
            navigate("/game/home")
    }

    return (
        <>
            {playerStore.player && <div className={"user-card-container"}>
                <button type={"button"} disabled={gameStore.isOffline} onClick={onClickUserCard}>
                    <div className={"user-card-content"}>
                        <img src={playerStore.player.avatar_url ?? "profile0.jpg"} alt={"profile image"}/>
                        <h2>{playerStore.player?.name}</h2>
                    </div>
                </button>
            </div>}
    </>
    )
})