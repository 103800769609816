import React, {PropsWithChildren} from 'react';
import {Footer} from "../../kit/components/footer";
import {Header} from "../../kit/components/header";
import {Outlet} from "react-router";
import {observer} from "mobx-react";

export const GenericPageWrapper = observer((props: PropsWithChildren) => {
    return (
        <>
            <Header/>
            <Outlet/>
            <Footer/>
        </>
    )
})