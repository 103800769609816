import React, {useEffect, useState} from "react";
import './index.scss'
import { observer } from 'mobx-react'
import {GameButton} from "../../components/buttons/GameButton.tsx";
import rootStore from "../../../stores/RootStore";
import {validateUserInputString, validateUserInputStringNoProfanity} from "../../../validation";
import {useNavigate} from "react-router";

export const CreatePlayerPage = observer(() => {

    const [name, setName] = useState({value: 'displayname'})
    const navigate = useNavigate()

    const onSubmit = async () => {
        if(validateUserInputStringNoProfanity(name.value) && rootStore.authStore.currentUser?.userId){
            await rootStore.playerStore.createPlayer(name.value, rootStore.authStore.currentUser?.userId).then(() => {
                rootStore.gameStore.setGameState("inactive")
                navigate('/game/home')
            })
        }
    }

    const isNameValid = () => {
        if (name.value.length < 3 || name.value.length > 20) {
            return false;
        }
        const alphanumericRegex = /^[a-zA-Z0-9_]+$/;
        return (alphanumericRegex.test(name.value) && validateUserInputStringNoProfanity(name.value));
    }

    const onNameChange = (event :any) => {
        event.preventDefault();
        if(validateUserInputString(name.value)){
            setName({value: event.target.value})
        } else {
            setName({value: "invalid"})
        }
    }

    useEffect(() => {
        if(rootStore.playerStore.player){
            navigate('/game/home', {replace: true})
        }
    }, [rootStore.playerStore.player])

    return (
        <div className='create-player-container'>
            <div className="card-header">
                <h4> Create your player name </h4>
            </div>
            <div className={"create-player-form-container"}>
                <form className={"create-player-form"} onSubmit={onSubmit}>
                    <input className={"create-player-name-input"} value={name.value} onChange={onNameChange}/>
                    <GameButton className={"create-player-submit-button"} onClick={onSubmit} disabled={!isNameValid()}>
                        Create player
                    </GameButton>
                </form>
            </div>
        </div>
    )
})