import React, {useEffect, useState} from 'react'
import './index.scss'
import {observer} from "mobx-react";
import {clsx} from "clsx";
import rootStore from "../../../../../stores/RootStore";
import {TmdbCredditedDetails} from "../../../../../types/tmdb/movie.tsx";
import {PATH_TO_TMDB_URL} from "../../../../../util";
import {getActorSearchSuggestions} from "../../../../../api/google.tsx";

export interface GuessCastCrewComponentProps {
    formNumber: number
}
export const GuessCastCrewComponent = observer((props: GuessCastCrewComponentProps) => {
    const {movieStore, uiStore, advancedGameWindowStore, gameStore, cookieStore} = rootStore

    useEffect(() => {
        if(currentMovieId && (currentMovieId !== movieStore.movie?.id)){
            setCreditToDisplay(undefined)
            setNumberOfGuesses(0)
            setCurrentNameGuessValue({name:  "Enter guess here..."})
            setCurrentMovieId(movieStore.movie?.id)
        }
    }, [movieStore.movie, cookieStore.userData])

    const getNumberOfWrongGuessesFromCookies = (): number => {
        if(cookieStore.userData?.dleData && cookieStore.userData.dleData.dleMovieId === movieStore.movie?.id){
            switch (formNumber) {
                case 1:
                    if(cookieStore.userData.dleData.numberOfWrongGuessesActorOne){
                        return cookieStore.userData.dleData.numberOfWrongGuessesActorOne
                    }
                    break

                case 2:
                    if(cookieStore.userData.dleData.numberOfWrongGuessesActorTwo){
                        return cookieStore.userData.dleData.numberOfWrongGuessesActorTwo
                    }
                    break

                case 3:
                    if(cookieStore.userData.dleData.numberOfWrongGuessesActorThree){
                        return cookieStore.userData.dleData.numberOfWrongGuessesActorThree
                    }
                    break
                case 4:
                    if(cookieStore.userData.dleData.numberOfWrongGuessesActorFour){
                        return cookieStore.userData.dleData.numberOfWrongGuessesActorFour
                    }
                    break
                default:
                    return 0
            }
        }
        return 0
    }

    const getCorrectAnswerFromCookies = () => {
        if(cookieStore.userData?.dleData?.correctGuesses &&
            !!cookieStore.userData?.dleData?.correctGuesses[props.formNumber - 1] &&
            cookieStore.userData.dleData.dleMovieId && movieStore.movie?.id &&
            cookieStore.userData.dleData.dleMovieId === movieStore.movie?.id){
            console.log("returning answer from cookies for movie id: " + cookieStore.userData.dleData.dleMovieId)
            return cookieStore.userData?.dleData?.correctGuesses[props.formNumber - 1]
        } else {
            return undefined
        }

    }
    const {formNumber} = props
    const [autoComplete, setAutoComplete] = useState("")
    const [currentNameGuessValue, setCurrentNameGuessValue] = useState({name:  "Enter guess here..."})
    const [numberOfGuesses, setNumberOfGuesses] = useState(getNumberOfWrongGuessesFromCookies())
    const [creditToDisplay, setCreditToDisplay] = useState<TmdbCredditedDetails | undefined>(getCorrectAnswerFromCookies())
    const [contentIsExpanded, setContentIsExpanded] = useState(false)
    const [currentMovieId, setCurrentMovieId] = useState(movieStore.movie?.id ?? undefined)
    const onSubmit = async (event: any) => {
        event.preventDefault()
        const newGuessCount = numberOfGuesses  + 1
        setNumberOfGuesses(newGuessCount)
        if (gameStore.gameState === 'dle') {
            await submitDleGuess(newGuessCount)
        } else {
            await submitGuess()
        }
    }

    const submitGuess = async () => {
        let result = await advancedGameWindowStore.submitGuess(currentNameGuessValue.name, (numberOfGuesses+1 >= 3))
        if(result && result.name){
            setCreditToDisplay(result)
            setContentIsExpanded(true)
        }

    }

    const submitDleGuess = async (newGuessCount: number) => {
        const result = await advancedGameWindowStore.submitGuess(currentNameGuessValue.name, (numberOfGuesses+1 >= 3))
        if(result){
            if(cookieStore.userData?.dleData?.dleMovieId === movieStore.movie?.id){

                cookieStore.setDleCorrectActorGuess(result)
            } else {
                cookieStore.incrementWrongActorGuessCount(formNumber)
            }
            setCreditToDisplay(result)
        }
    }

    const onClickAutoComplete = async (event: any) => {
        event.preventDefault()
        setCurrentNameGuessValue({name: autoComplete} )
        await onSubmit(event)
    }


    const onGuessChange = async (event: any) => {
        //const result = await getActorSearchSuggestions(event.target.value, movieStore.movie?.title)
        setCurrentNameGuessValue({name: event.target.value})
    }

    const getColorFromNumGuesses = () => {
        switch(numberOfGuesses){
            case 0:
                return 'green'
            case 1:
                return 'yellow'
            case 2:
                return 'red'
            case 3:
                return 'gray'
            default:
                return 'gray'
        }
    }

    useEffect(() => {

        if(movieStore.movie?.id){
            if(currentMovieId === undefined){
                setCurrentMovieId(movieStore.movie.id)
            } else if(currentMovieId !== movieStore.movie.id){
                setCreditToDisplay(undefined)
                setAutoComplete("")
                setNumberOfGuesses(0)
                setCurrentNameGuessValue({name: "Enter guess here..."})
                setCurrentMovieId(movieStore.movie.id)
            }
        }
    }, [movieStore.movie])

    useEffect(() => {
        if(currentNameGuessValue.name && currentNameGuessValue.name.length && currentNameGuessValue.name.length > 3){
            getActorSearchSuggestions(currentNameGuessValue.name, movieStore.movie?.title).then((result) => {
                if(result?.toLowerCase().includes(currentNameGuessValue.name.toLowerCase())){
                    setAutoComplete(result)
                } else {
                    return false
                }

            })
        } else {
            setAutoComplete("")
        }
    }, [currentNameGuessValue.name])

    return (
        <div className="guess-container">
            <div className={"guess-header"}>
                <form className={"guess-form"} onSubmit={onSubmit}>
                    <div className={"guess-header-left-side"}>
                        <button className={clsx("header-submit-button", getColorFromNumGuesses())} type={"submit"} disabled={(numberOfGuesses >= 3) || !!creditToDisplay}>
                            Submit
                        </button>
                    </div>
                    <div className={"guess-header-right-side"} onClick={(event: any) => {
                        event.preventDefault()
                        if (creditToDisplay) {
                            setContentIsExpanded(!contentIsExpanded)
                        }
                    }}>
                        <div className={"input-wrapper"}>
                            <input type={"text"}
                                   className={clsx("header-input", creditToDisplay && 'active')}
                                   value={currentNameGuessValue.name}
                                   disabled={!!creditToDisplay || numberOfGuesses >= 3}
                                   onChange={onGuessChange}
                                   onClick={(event: any) => {
                                       event.preventDefault();
                                       if (currentNameGuessValue.name === "Enter guess here...") {
                                           setCurrentNameGuessValue({name: ""})
                                       }
                                       if (creditToDisplay) {
                                           setContentIsExpanded(!contentIsExpanded)
                                       }
                                   }}
                                   onBlur={(event: any) => {
                                       event.preventDefault();
                                       if(currentNameGuessValue.name === ""){
                                           setCurrentNameGuessValue({name: "Enter guess here..."})
                                       }}}
                                   onMouseEnter={() => {uiStore.setIsDraggableLocked(true)}}
                                   onMouseLeave={() => {uiStore.setIsDraggableLocked(false)}}
                            />
                            { autoComplete && !creditToDisplay &&
                                <button className={"autocomplete-wrapper"}
                                        onFocus={() => setCurrentNameGuessValue({name: autoComplete})}
                                        type={"submit"}
                                        onClick={onClickAutoComplete}>
                                    {autoComplete}
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div className={clsx("guess-content", !creditToDisplay && !contentIsExpanded && 'hide')}>
                {
                    creditToDisplay &&
                    <div className={"guess-content-body"}>
                        <div className={"guess-content-left-side"}>
                            <img src={PATH_TO_TMDB_URL(creditToDisplay?.profile_path)} alt={"Picture of " + creditToDisplay?.original_name}/>
                        </div>
                        <div className={"guess-content-right-side"}>
                            <h3>{creditToDisplay.original_name}</h3>
                            <h5>{creditToDisplay.character}</h5>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
})