import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import './index.scss'
import Slider from "@mui/material/Slider";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {clsx} from "clsx";
import { signOut } from 'aws-amplify/auth';
import rootStore from "../../../stores/RootStore";
import {useNavigate} from "react-router";


export const SettingsPage = observer(() => {
    const {gameStore, musicStore, cookieStore, playerStore} = rootStore
    const navigate = useNavigate()

    const onChangeMusicVolume = (event: any) => {
        event.preventDefault()
        musicStore.setMusicVolume(event.target.value)
    }

    const onChangeUiVolume = (event: any) => {
        event.preventDefault()
        musicStore.setUiVolume(event.target.value)
        musicStore.triggerButtonHoverAudio()
    }

    const onClickMuteButton = (event: any) => {
        event.preventDefault()
        if(musicStore.audioMode === 'on'){
            musicStore.setAudioMode('off')
        } else {
            musicStore.setAudioMode('on')
        }
    }

    const getMuteButtonImagePath = () => {
        if(musicStore.audioMode === 'on'){
            return '/unmuted.svg'
        } else {
            return '/muted.svg'
        }
    }

    const leaveSettingsPage = (event: any) => {
        event.preventDefault()
        let newUserData = {...cookieStore.userData}

        newUserData.settings = {
            uiVolume: musicStore.uiVolume,
            musicVolume: musicStore.musicVolume,
            isAudioMuted: musicStore.audioMode === 'off',
            hasInteractedWithSettings: true,
            hasConfirmedTermsOfService: newUserData.settings?.hasConfirmedTermsOfService ?? false
        }

        cookieStore.setUserData(newUserData)

        if(playerStore.player?.name){
            gameStore.setGameState('inactive')
            navigate('/game/home')
        } else {
            navigate('/game/login')
        }
    }


    useEffect(() => {
        cookieStore.loadUserData().then(() => {})
    }, [rootStore.musicStore])

    return (
        <>
            <div className="card-header">
                Please select your audio preference.
            </div>
            <div className='audio-settings-container'>
                <div className={"music-volume-slider-container"}>
                    <h3> Music Volume </h3>
                <Slider min={0}
                        max={100}
                        step={5}
                        valueLabelDisplay={"auto"}
                        scale={(value: any) => (value)}
                        onChange={onChangeMusicVolume}
                        defaultValue={musicStore.musicVolume}
                        value={musicStore.musicVolume}
                        className={"music-volume-slider"}/>
                </div>
                <div className={"ui-volume-slider-container"}>
                    <h3> UI Volume </h3>
                    <Slider min={0}
                            max={100}
                            step={5}
                            valueLabelDisplay={"auto"}
                            onChange={onChangeUiVolume}
                            defaultValue={musicStore.uiVolume}
                            value={musicStore.uiVolume}
                            className={"ui-volume-slider"}/>
                </div>
                <div className={"settings-buttons-container"}>
                <GameButton className={clsx("mute-button", musicStore.audioMode === "off" ? "red" : "green" )} onClick={onClickMuteButton}>
                    <>
                        {musicStore.audioMode === "on" ? "Sound On" : "Sound Off"}
                        <img src={getMuteButtonImagePath()} alt={"volume-toggle"}/>

                    </>
                </GameButton>
                <GameButton className={clsx("exit-settings-button", "yellow")} onClick={leaveSettingsPage}>
                    {"<- Back"}
                </GameButton>
                </div>


            </div>
        </>
    )
})