import React from 'react';
import {observer} from "mobx-react";
import rootStore from "../../../../stores/RootStore";
import './index.scss'


export const ErrorPopup = observer(()=>{
    const error = rootStore.popupStore.errorPopup
    if(!error?.isOpen || !error.message) {
        return null
    }

    return (
        <div className="error-banner">
        <span className="close-button" onClick={()=>rootStore.popupStore.closeErrorPopup()}>
            [X]
        </span>
        <div className='error-message'>
            {error.message}
        </div>
      </div>
    )
})