

import {makeAutoObservable} from "mobx";
import {GameStore} from "../GameStore";
import {MusicStore} from "../MusicStore";
import AdvancedGameWindowStore from "../AdvancedGameWindowStore";
import DleGameStore from "../DleGameStore";
import {CookieStore} from "../CookieStore";
import AuthStore from "../AuthStore";
import {MovieStore} from "../MovieStore";
import {PlayerStore} from "../PlayerStore";
import {SessionConnectionStore} from "../SessionConnectionStore";
import {SessionStore} from "../SessionStore";
import {SessionRoundStore} from "../SessionRoundStore";
import UIStore from "../UIStore";
import {SubscriptionStore} from "../SubscriptionStore";
import {PopupStore} from "../PopupStore";

export class RootStore {
    musicStore: MusicStore
    gameStore: GameStore
    advancedGameWindowStore: AdvancedGameWindowStore
    dleGameStore: DleGameStore
    cookieStore: CookieStore
    authStore: AuthStore
    sessionConnectionStore: SessionConnectionStore
    sessionStore: SessionStore
    sessionRoundStore: SessionRoundStore
    subscriptionStore: SubscriptionStore
    movieStore: MovieStore
    playerStore: PlayerStore
    uiStore: UIStore
    popupStore: PopupStore

    constructor() {
        makeAutoObservable(this)
        this.musicStore = new MusicStore()
        this.gameStore = new GameStore(this)
        this.advancedGameWindowStore = new AdvancedGameWindowStore(this)
        this.dleGameStore = new DleGameStore(this)
        this.cookieStore = new CookieStore(this)
        this.authStore = new AuthStore(this)
        this.sessionStore = new SessionStore(this)
        this.sessionRoundStore = new SessionRoundStore(this)
        this.movieStore = new MovieStore(this)
        this.sessionConnectionStore = new SessionConnectionStore(this)
        this.subscriptionStore = new SubscriptionStore(this)
        this.playerStore = new PlayerStore(this)
        this.uiStore = new UIStore(this)
        this.popupStore = new PopupStore(this)
    }
}

const rootStore = new RootStore()
export default rootStore