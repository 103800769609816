import {RootStore} from "../RootStore";
import {action, makeAutoObservable} from "mobx";
import {PlayerSessionConnection} from "../../API.ts";
import {
    createNewPlayerSessionConnection,
    deleteExistingPlayerSessionConnection,
    updateExistingPlayerSessionConnection
} from "../../api/graphql.tsx";


export class SessionConnectionStore {
    get isHost(): boolean {
        return this._isHost;
    }

    @action
    setIsHost(value: boolean) {
        this._isHost = value;
    }
    get isGuest(): boolean {
        return this._isGuest;
    }

    @action
    setIsGuest(value: boolean) {
        this._isGuest = value;
    }
    get waitingForConnection(): boolean {
        return this._waitingForConnection;
    }

    setWaitingForConnection(value: boolean) {
        this._waitingForConnection = value;
    }
    get sessionConnection(): PlayerSessionConnection | undefined {
        return this._sessionConnection;
    }

    @action
    setSessionConnection(value: PlayerSessionConnection | undefined) {
        this._sessionConnection = value;
    }

    private _rootStore: RootStore;
    private _sessionConnection: PlayerSessionConnection | undefined
    private _waitingForConnection: boolean = false
    private _isGuest: boolean = false
    private _isHost: boolean = false
    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    @action
    async connectToSession(roomCode: string, isHost: boolean = false) {
        if(this._rootStore.playerStore.player){
            await createNewPlayerSessionConnection(this._rootStore.authStore.accessToken?.toString(),
                this._rootStore.playerStore.player,
                roomCode,
                isHost,
                this.onSessionConnectionUpdate,
                this._rootStore.sessionStore.publicSession?.id,
                this._rootStore.subscriptionStore.addSubscription)
            if(isHost){
                this.setIsGuest(false)
                this.setIsHost(true)
            } else {
                this.setIsHost(false)
                this.setIsGuest(true)
            }
            this.setWaitingForConnection(true)
        }

    }

    @action
    async onSessionConnectionUpdate(sessionConnection: PlayerSessionConnection) {
        if(this.waitingForConnection){
            await this.onHostReply(sessionConnection)
        } else if(this.sessionConnection?.host_approved && this.sessionConnection.public_session_id){
            if(!sessionConnection.connection_is_closed){
                if(this.sessionConnection?.id === sessionConnection.id){
                    this.setSessionConnection(sessionConnection)
                } else{
                    await this._rootStore.sessionStore.handleSessionPlayersUpdate(sessionConnection)
                }

            } else {
                this._rootStore.sessionStore.removePlayerSessionConnectionById(sessionConnection.id)
            }

        }
    }

    @action
    async addNewScore(score: number){
        let newPlayerConnectionData = this.sessionConnection
        if(newPlayerConnectionData?.player_score){
            newPlayerConnectionData.player_score = newPlayerConnectionData.player_score + score
            await updateExistingPlayerSessionConnection(this._rootStore.authStore.accessToken?.toString(), newPlayerConnectionData)
        } else if (newPlayerConnectionData){
            newPlayerConnectionData.player_score = score
            await updateExistingPlayerSessionConnection(this._rootStore.authStore.accessToken?.toString(), newPlayerConnectionData)
        }
    }

    @action
    async closeCurrentSessionConnection() {
        this._rootStore.gameStore.setIsLoading(true)
        //used to remove guest and host from session
        let newSessionConnection = this._sessionConnection
        if(newSessionConnection){
            newSessionConnection.connection_is_closed = true
            await updateExistingPlayerSessionConnection(this._rootStore.authStore.accessToken?.toString(), newSessionConnection)
            this.setSessionConnection(undefined)
            this._rootStore.sessionStore.onPublicSessionClosed()
        }
        this._rootStore.gameStore.setIsLoading(false)
    }

    @action
    async onHostReply(sessionConnection: PlayerSessionConnection){
        if(sessionConnection.host_approved && sessionConnection.public_session_id){
            await this._rootStore.sessionStore.joinPublicSession(sessionConnection.public_session_id)
            this.setSessionConnection(sessionConnection)
            this.setWaitingForConnection(false)
        } else if(sessionConnection.host_denied){
            this.setSessionConnection(undefined)
            this.setWaitingForConnection(false)
        }
    }

    @action
    resetStore(){
        this.setSessionConnection(undefined)
        this.setWaitingForConnection(false)
        this.setIsGuest(false)
        this.setIsHost(false)
    }


}