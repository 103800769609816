import React, {useState} from 'react'
import './index.scss'
import clsx from "clsx";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {useNavigate} from "react-router";

export const LandingPage = () => {
    const [currentContentName, setCurrentContentName] = useState<'privacy' | 'roadmap' | 'rules'>('roadmap')

    const navigate = useNavigate()
    const getCurrentContentBody = () => {
        switch (currentContentName) {
            case 'privacy':
                return <div className="content">
                    <h2>User Data</h2>
                    <ul>
                        <li>
                            Google Sign-In: When signing in to Megacritic
                            with your Google account, you are granting this app
                            access to your email and profile information. No
                            personal information will be shared with other users,
                            and will only be used for authentication purposes.
                        </li>
                        <li>
                            Cookies: Megacritic uses cookies by default to deliver
                            essential game functionality. Megacritic DOES NOT store
                            any data pertaining to the user or the user's device.
                        </li>
                    </ul>
                </div>
            case 'roadmap':
                return <div className="content">
                    <h2>v0.6.2-alpha - 4/7/2024</h2>
                    <ul>
                        <li>
                            Post-round review stats for each player in the session.
                        </li>
                        <li>
                            Bug fix for users stuck on "create player" step after login. Various multiplayer fixes.
                        </li>

                    </ul>

                    <h2>Planned Features</h2>
                    <ul>
                        <li>
                            Audience participation (for streams or large groups)
                        </li>
                        <li>
                            More statistics and data to track your performance
                        </li>
                        <li>
                            More customizable session rules.
                        </li>
                        <li>
                            Proper game over screen with a review of the session.
                        </li>
                    </ul>
                </div>
            case 'rules':
                return <div className="content">
                    <h2>Game Rules</h2>
                    <ul>
                        <li>
                            Premise: The rules to Megacritic are simple. When the round starts,
                            a random film will be chosen. You must guess as close to the average user rating (1 through 10)
                            as you can. You may earn up to 300 points per round.
                        </li>
                        <li>
                            Rating Guess: If you guess the exact average user rating for a film,
                            you will earn 100 points. You lose 5 points per .1 difference between your
                            answer and the correct answer. If your guess is more than 2.5 points away
                            from the correct answer, you will not receive any points.
                        </li>
                        <li>
                            Hints: Currently you are able to score a total of 300 points.
                            When a round begins, you start with 100 points that you are allowed
                            to spend on hints. For instance, if you do not know the film, you might
                            choose to reveal a review by a real user to help point you in the right direction.
                        </li>
                        <li>
                            Bonus points: Currently, you may earn 100 extra points by correctly guessing
                            up to 4 people credited on the film. This includes anything from the top billed actors to the camera crew.
                            You get a total of 12 guesses per round, and earn 25 points for each correct crew member named.
                        </li>
                    </ul>
                </div>
        }
    }

    return (
        <div className={"landing-page-root"}>
            <div className={"landing-page-container"}>
                <div className={"landing-page-info-window"}>
                    <div className={"window-header"}>
                        <h1>Welcome to MegaCritic</h1>
                    </div>
                    <div className={"window-content"}>
                        <div className={"left-side-content"}>
                            <div className={"left-side-content-header"}>
                                <button name={"View Policies"} className={clsx("privacy-button", (currentContentName === "privacy") && "active")} onClick={() => (setCurrentContentName("privacy"))}>
                                    Policies
                                </button>
                                <button name={"View Roadmap"} className={clsx("roadmap-button", (currentContentName === "roadmap") && "active")} onClick={() => (setCurrentContentName("roadmap"))}>
                                    Roadmap
                                </button>
                                <button name={"View Game Rules"} className={clsx("rules-button", (currentContentName === "rules") && "active")} onClick={() => (setCurrentContentName("rules"))}>
                                    Game Info
                                </button>
                            </div>
                            <div className={"left-side-content-body"}>
                                {getCurrentContentBody()}
                            </div>
                        </div>
                        <div className={"right-side-content"}>
                            <div className={"right-side-content-header"}>
                                <h2>Disclaimer</h2>
                            </div>
                            <div className={"right-side-content-body"}>
                                By continuing to the rest of the app, you are accepting
                                the <a href={"/terms-of-service"}>Terms of Service</a> and <a href={"/privacy-policy"}>Privacy Policy</a> of Megacritic.com.
                                Any user that violates these terms may have their access to the app revoked or restricted.
                            </div>
                            <div className={"right-side-button-container"}>
                                <GameButton className={"consent-button"} onClick={() => {
                                    navigate('/game/login')
                                }}>
                                    I understand
                                </GameButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}