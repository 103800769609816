import React, { useState } from 'react'
import { observer } from 'mobx-react';
import rootStore, {RootStore} from "../../../stores/RootStore";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import './index.scss'
import {SessionSettingsInput} from "../../../API.ts";
import {useNavigate} from "react-router";
import {CustomSessionStep} from "./CustomSessionStep";
import {MovieFilterStep} from "./MovieFilterStep";

export const HostCreatePage = observer(() => {
    const [currentStep, setCurrentStep] = useState(1)

    const onSubmitMovieFilter = () => {
        setCurrentStep(2)
    }

    return (
        <div className="hosting-section-container">
            {currentStep === 1 ? <MovieFilterStep onSubmit={onSubmitMovieFilter}/> : <CustomSessionStep/>}
        </div>
    )
})