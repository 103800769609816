import React, {useEffect, useState} from 'react'
import './index.scss'
import { MoviePosterSection } from './moviePosterSection'
import clsx from 'clsx'
import Slider from '@mui/material/Slider'
import {GameOverSection} from "./gameOverSection";
import {observer} from "mobx-react";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import rootStore from "../../../stores/RootStore";
import {GameTimer} from "../../components/gameTimer";
import {useNavigate} from "react-router";

export const GuessPage = observer(() => {
    const {
        gameStore,
        dleGameStore,
        movieStore,
        uiStore} = rootStore
    const [currentGuess, setCurrentGuess] = useState({value: gameStore.playerGuessValue})
    const navigate = useNavigate()
    const submitRatingGuess = async (event: any) => {
        event.preventDefault()
        if(gameStore.gameState == 'dle' && currentGuess.value){
            dleGameStore.setSubmittedGuess(currentGuess.value)
            gameStore.setPlayerScoreThisRound(currentGuess.value)
            await gameStore.submitCurrentGuess()
        } else {
            gameStore.submitCurrentGuess().then(()=>{})
        }
      }

    const onGuessChange = (event: any) => {
        if(typeof +event.target.value === 'number'){
            setCurrentGuess({value: event.target.value})
            gameStore.setPlayerGuessValue(event.target.value)
        }

    }
    const onSliderChange = (event: any) => {
        onGuessChange({target: { value: +(event.target.value/10).toFixed(1)}})
    }

    const getGuessDisplayValue = (val: number) => {
        if(val === 10){
            return 10
        } else {
            const newNumber = +val
            return +newNumber.toFixed(1)
        }
    }

    const currentTitleLength = movieStore.movie?.title ? movieStore.movie?.title?.length : 0

    useEffect(() => {
        if (gameStore.isInactive()){
            navigate('/game/home')
        }
    }, []);

    useEffect(() => {
        const unloadCallback = (event: any) => {
            event.preventDefault();
            event.returnValue = "If you refresh or leave this session, you cannot rejoin.";
            return "If you refresh or leave this session, you cannot rejoin.";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);


    return (
        <>
            <div className="game-active-window">
                <div className={"game-header-container"}>
                    <div className={clsx("movie-title-container", currentTitleLength > 12 && 'long')}>
                        {currentTitleLength > 22 ?
                            <h4>
                                {movieStore.movie?.title}
                            </h4> :
                            <h3>
                                {movieStore.movie?.title}
                            </h3>}
                    </div>
                </div>
                <MoviePosterSection imageUrl={movieStore.moviePosterImageUrl() ?? ''}/>
                <GameTimer/>

            {
                gameStore.playerScoreThisRound === undefined && !(gameStore.gameState === 'dle' && dleGameStore.submittedGuess) ?

                <form className="game-form" onSubmit={submitRatingGuess}>
                    <div className="score-and-submit-container">
                        <GameButton className={clsx("submit-button", uiStore.scoreColor())}
                                    type='submit'
                                    disabled={!currentGuess.value || (currentGuess.value > 10 || currentGuess.value <= 0)}>
                            Submit Guess
                        </GameButton>
                        <input className={clsx("score-input-field", uiStore.scoreColor())} disabled={true} type="float" min={0.0} max={10} value={getGuessDisplayValue(currentGuess.value ?? 5)} onChange={onGuessChange}/>
                    </div>
                    <Slider min={1}
                            step={1}
                            max={100}
                            scale={(value: any) => (value)}
                            className={clsx("value-slider", uiStore.scoreColor())}
                            valueLabelDisplay="auto"
                            onChange={onSliderChange}
                            value={+(Math.round((currentGuess.value ?? 0)*10))}/>
                </form> : <GameOverSection />
            }
            </div>
        </>
    )
})
