import axios from "axios";


const GOOGLE_API_KEY = "AIzaSyCtOZI2ApsYhHv4jloKFUQBCaxGEiUaDkw"
const GEKG_BASE_GET_URI =
    "https://enterpriseknowledgegraph.googleapis.com/v1/projects/megacritic/locations/global/publicKnowledgeGraphEntities:lookup?key=" +
    GOOGLE_API_KEY
const GKG_BASE_SEARCH_URI =
    "https://kgsearch.googleapis.com/v1/entities:search?key="+GOOGLE_API_KEY

const config = {
    headers: {
        accept: 'application/json',
    }
}

export const getActorSearchSuggestions = async (searchValue: string, movieName?: string) => {
    const response = await axios.get(GKG_BASE_SEARCH_URI + "&types=Person&limit=1&query="+searchValue)
    if (response.data) {
        return response.data?.itemListElement[0]?.result?.name
    }
}