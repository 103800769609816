/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreatePlayerSessionConnection = /* GraphQL */ `subscription OnCreatePlayerSessionConnection(
  $filter: ModelSubscriptionPlayerSessionConnectionFilterInput
  $owner: String
) {
  onCreatePlayerSessionConnection(filter: $filter, owner: $owner) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlayerSessionConnectionSubscriptionVariables,
  APITypes.OnCreatePlayerSessionConnectionSubscription
>;
export const onUpdatePlayerSessionConnection = /* GraphQL */ `subscription OnUpdatePlayerSessionConnection(
  $filter: ModelSubscriptionPlayerSessionConnectionFilterInput
  $owner: String
) {
  onUpdatePlayerSessionConnection(filter: $filter, owner: $owner) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlayerSessionConnectionSubscriptionVariables,
  APITypes.OnUpdatePlayerSessionConnectionSubscription
>;
export const onDeletePlayerSessionConnection = /* GraphQL */ `subscription OnDeletePlayerSessionConnection(
  $filter: ModelSubscriptionPlayerSessionConnectionFilterInput
  $owner: String
) {
  onDeletePlayerSessionConnection(filter: $filter, owner: $owner) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlayerSessionConnectionSubscriptionVariables,
  APITypes.OnDeletePlayerSessionConnectionSubscription
>;
export const onCreateAudienceGuess = /* GraphQL */ `subscription OnCreateAudienceGuess(
  $filter: ModelSubscriptionAudienceGuessFilterInput
) {
  onCreateAudienceGuess(filter: $filter) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAudienceGuessSubscriptionVariables,
  APITypes.OnCreateAudienceGuessSubscription
>;
export const onUpdateAudienceGuess = /* GraphQL */ `subscription OnUpdateAudienceGuess(
  $filter: ModelSubscriptionAudienceGuessFilterInput
) {
  onUpdateAudienceGuess(filter: $filter) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAudienceGuessSubscriptionVariables,
  APITypes.OnUpdateAudienceGuessSubscription
>;
export const onDeleteAudienceGuess = /* GraphQL */ `subscription OnDeleteAudienceGuess(
  $filter: ModelSubscriptionAudienceGuessFilterInput
) {
  onDeleteAudienceGuess(filter: $filter) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAudienceGuessSubscriptionVariables,
  APITypes.OnDeleteAudienceGuessSubscription
>;
export const onCreateAudienceSessionConnection = /* GraphQL */ `subscription OnCreateAudienceSessionConnection(
  $filter: ModelSubscriptionAudienceSessionConnectionFilterInput
  $owner: String
) {
  onCreateAudienceSessionConnection(filter: $filter, owner: $owner) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAudienceSessionConnectionSubscriptionVariables,
  APITypes.OnCreateAudienceSessionConnectionSubscription
>;
export const onUpdateAudienceSessionConnection = /* GraphQL */ `subscription OnUpdateAudienceSessionConnection(
  $filter: ModelSubscriptionAudienceSessionConnectionFilterInput
  $owner: String
) {
  onUpdateAudienceSessionConnection(filter: $filter, owner: $owner) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAudienceSessionConnectionSubscriptionVariables,
  APITypes.OnUpdateAudienceSessionConnectionSubscription
>;
export const onDeleteAudienceSessionConnection = /* GraphQL */ `subscription OnDeleteAudienceSessionConnection(
  $filter: ModelSubscriptionAudienceSessionConnectionFilterInput
  $owner: String
) {
  onDeleteAudienceSessionConnection(filter: $filter, owner: $owner) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAudienceSessionConnectionSubscriptionVariables,
  APITypes.OnDeleteAudienceSessionConnectionSubscription
>;
export const onCreateReservedPlayerName = /* GraphQL */ `subscription OnCreateReservedPlayerName(
  $filter: ModelSubscriptionReservedPlayerNameFilterInput
  $owner: String
) {
  onCreateReservedPlayerName(filter: $filter, owner: $owner) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReservedPlayerNameSubscriptionVariables,
  APITypes.OnCreateReservedPlayerNameSubscription
>;
export const onUpdateReservedPlayerName = /* GraphQL */ `subscription OnUpdateReservedPlayerName(
  $filter: ModelSubscriptionReservedPlayerNameFilterInput
  $owner: String
) {
  onUpdateReservedPlayerName(filter: $filter, owner: $owner) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReservedPlayerNameSubscriptionVariables,
  APITypes.OnUpdateReservedPlayerNameSubscription
>;
export const onDeleteReservedPlayerName = /* GraphQL */ `subscription OnDeleteReservedPlayerName(
  $filter: ModelSubscriptionReservedPlayerNameFilterInput
  $owner: String
) {
  onDeleteReservedPlayerName(filter: $filter, owner: $owner) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReservedPlayerNameSubscriptionVariables,
  APITypes.OnDeleteReservedPlayerNameSubscription
>;
export const onCreatePublicSession = /* GraphQL */ `subscription OnCreatePublicSession(
  $filter: ModelSubscriptionPublicSessionFilterInput
  $owner: String
) {
  onCreatePublicSession(filter: $filter, owner: $owner) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePublicSessionSubscriptionVariables,
  APITypes.OnCreatePublicSessionSubscription
>;
export const onUpdatePublicSession = /* GraphQL */ `subscription OnUpdatePublicSession(
  $filter: ModelSubscriptionPublicSessionFilterInput
  $owner: String
) {
  onUpdatePublicSession(filter: $filter, owner: $owner) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePublicSessionSubscriptionVariables,
  APITypes.OnUpdatePublicSessionSubscription
>;
export const onDeletePublicSession = /* GraphQL */ `subscription OnDeletePublicSession(
  $filter: ModelSubscriptionPublicSessionFilterInput
  $owner: String
) {
  onDeletePublicSession(filter: $filter, owner: $owner) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePublicSessionSubscriptionVariables,
  APITypes.OnDeletePublicSessionSubscription
>;
export const onCreateMovieDle = /* GraphQL */ `subscription OnCreateMovieDle($filter: ModelSubscriptionMovieDleFilterInput) {
  onCreateMovieDle(filter: $filter) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMovieDleSubscriptionVariables,
  APITypes.OnCreateMovieDleSubscription
>;
export const onUpdateMovieDle = /* GraphQL */ `subscription OnUpdateMovieDle($filter: ModelSubscriptionMovieDleFilterInput) {
  onUpdateMovieDle(filter: $filter) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMovieDleSubscriptionVariables,
  APITypes.OnUpdateMovieDleSubscription
>;
export const onDeleteMovieDle = /* GraphQL */ `subscription OnDeleteMovieDle($filter: ModelSubscriptionMovieDleFilterInput) {
  onDeleteMovieDle(filter: $filter) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMovieDleSubscriptionVariables,
  APITypes.OnDeleteMovieDleSubscription
>;
export const onCreatePlayer = /* GraphQL */ `subscription OnCreatePlayer(
  $filter: ModelSubscriptionPlayerFilterInput
  $owner: String
) {
  onCreatePlayer(filter: $filter, owner: $owner) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlayerSubscriptionVariables,
  APITypes.OnCreatePlayerSubscription
>;
export const onUpdatePlayer = /* GraphQL */ `subscription OnUpdatePlayer(
  $filter: ModelSubscriptionPlayerFilterInput
  $owner: String
) {
  onUpdatePlayer(filter: $filter, owner: $owner) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlayerSubscriptionVariables,
  APITypes.OnUpdatePlayerSubscription
>;
export const onDeletePlayer = /* GraphQL */ `subscription OnDeletePlayer(
  $filter: ModelSubscriptionPlayerFilterInput
  $owner: String
) {
  onDeletePlayer(filter: $filter, owner: $owner) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlayerSubscriptionVariables,
  APITypes.OnDeletePlayerSubscription
>;
export const onCreateSessionRound = /* GraphQL */ `subscription OnCreateSessionRound(
  $filter: ModelSubscriptionSessionRoundFilterInput
  $owner: String
) {
  onCreateSessionRound(filter: $filter, owner: $owner) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSessionRoundSubscriptionVariables,
  APITypes.OnCreateSessionRoundSubscription
>;
export const onUpdateSessionRound = /* GraphQL */ `subscription OnUpdateSessionRound(
  $filter: ModelSubscriptionSessionRoundFilterInput
  $owner: String
) {
  onUpdateSessionRound(filter: $filter, owner: $owner) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSessionRoundSubscriptionVariables,
  APITypes.OnUpdateSessionRoundSubscription
>;
export const onDeleteSessionRound = /* GraphQL */ `subscription OnDeleteSessionRound(
  $filter: ModelSubscriptionSessionRoundFilterInput
  $owner: String
) {
  onDeleteSessionRound(filter: $filter, owner: $owner) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSessionRoundSubscriptionVariables,
  APITypes.OnDeleteSessionRoundSubscription
>;
export const onCreatePlayerGuess = /* GraphQL */ `subscription OnCreatePlayerGuess(
  $filter: ModelSubscriptionPlayerGuessFilterInput
  $owner: String
) {
  onCreatePlayerGuess(filter: $filter, owner: $owner) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlayerGuessSubscriptionVariables,
  APITypes.OnCreatePlayerGuessSubscription
>;
export const onUpdatePlayerGuess = /* GraphQL */ `subscription OnUpdatePlayerGuess(
  $filter: ModelSubscriptionPlayerGuessFilterInput
  $owner: String
) {
  onUpdatePlayerGuess(filter: $filter, owner: $owner) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlayerGuessSubscriptionVariables,
  APITypes.OnUpdatePlayerGuessSubscription
>;
export const onDeletePlayerGuess = /* GraphQL */ `subscription OnDeletePlayerGuess(
  $filter: ModelSubscriptionPlayerGuessFilterInput
  $owner: String
) {
  onDeletePlayerGuess(filter: $filter, owner: $owner) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlayerGuessSubscriptionVariables,
  APITypes.OnDeletePlayerGuessSubscription
>;
export const onCreateMegacriticSession = /* GraphQL */ `subscription OnCreateMegacriticSession(
  $filter: ModelSubscriptionMegacriticSessionFilterInput
  $owner: String
) {
  onCreateMegacriticSession(filter: $filter, owner: $owner) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMegacriticSessionSubscriptionVariables,
  APITypes.OnCreateMegacriticSessionSubscription
>;
export const onUpdateMegacriticSession = /* GraphQL */ `subscription OnUpdateMegacriticSession(
  $filter: ModelSubscriptionMegacriticSessionFilterInput
  $owner: String
) {
  onUpdateMegacriticSession(filter: $filter, owner: $owner) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMegacriticSessionSubscriptionVariables,
  APITypes.OnUpdateMegacriticSessionSubscription
>;
export const onDeleteMegacriticSession = /* GraphQL */ `subscription OnDeleteMegacriticSession(
  $filter: ModelSubscriptionMegacriticSessionFilterInput
  $owner: String
) {
  onDeleteMegacriticSession(filter: $filter, owner: $owner) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMegacriticSessionSubscriptionVariables,
  APITypes.OnDeleteMegacriticSessionSubscription
>;
