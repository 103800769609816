import React from 'react';
import './index.scss'


export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-links">
                    <a href="/sources">Sources</a>
                    <a href={'/terms-of-service'}> Terms of Service</a>
                    <a href={'/privacy-policy'}> Privacy Policy</a>
                </div>
            </div>
        </div>
    )
}