import React from 'react'
import rootStore from "../../../../stores/RootStore";
import './index.scss'
import {observer} from "mobx-react";




export const MobileGameNav = observer(() => {
    const {movieStore, gameStore, advancedGameWindowStore, uiStore} = rootStore

    const shouldRenderButtons = () => {
        const playerInSession =
            (gameStore.isGuest() || gameStore.isHost()) && movieStore.movie
        const playerIsSolo = gameStore.isSolo() || gameStore.isDle()
        return playerInSession || playerIsSolo
    }
    const onClickInfo = (event: any) => {
        event.preventDefault();
        uiStore.setMobileWindowFocus("info")
    }

    const onClickMain = (event: any) => {
        event.preventDefault();
        uiStore.setMobileWindowFocus("main")
    }

    const onClickPoster = (event: any) => {
        event.preventDefault();
        uiStore.setMobileWindowFocus("poster")
    }
    return (
        <>
            {shouldRenderButtons() &&
                <div className="mobile-game-nav-container">
                    <div className="mobile-game-nav-row">
                        <button className={"game-info-button"} onClick={onClickInfo}>
                            Hints
                        </button>
                        <button className={"main-window-button"} onClick={onClickMain}>
                            Movie
                        </button>
                        {
                            advancedGameWindowStore.posterUnlocked &&
                                <button className={"movie-poster-button"} onClick={onClickPoster}>
                                    Poster
                                </button>
                        }
                    </div>
                </div>
            }
        </>

    )
})