import React from 'react'
import {UnlockItemComponent} from "./UnlockItemComponent";
import {UserReviewWidget} from "../../userReviewWidget";
import {GuessCastCrewComponent} from "./GuessCastCrewComponent";
import rootStore from "../../../../stores/RootStore";
import './index.scss'
import {observer} from "mobx-react";

export const GameInfoColumn = observer(() => {
    const {gameStore, cookieStore, sessionConnectionStore,  movieStore, sessionStore, advancedGameWindowStore} = rootStore

    const onClick = (unlockItemName: string, cost: number) => {
        switch(unlockItemName) {
            case('poster'):
                advancedGameWindowStore.setPosterUnlocked(true)
                advancedGameWindowStore.subtractFromPlayerScore(cost)
                cookieStore.setDlePosterIsUnlocked()
                break
            case('overview'):
                advancedGameWindowStore.setOverviewUnlocked(true)
                advancedGameWindowStore.subtractFromPlayerScore(cost)
                cookieStore.setDleOverviewIsUnlocked()
                break
            case('review'):
                advancedGameWindowStore.setReviewUnlocked(true)
                advancedGameWindowStore.subtractFromPlayerScore(cost)
                cookieStore.setDleReviewIsUnlocked()
                break
        }
    }

    return (
        <div className={'game-info-column'}>
            {
                movieStore.movie ||
                    (sessionConnectionStore.sessionConnection?.player_score &&
                        sessionConnectionStore.sessionConnection?.player_score  > 0)  ?
                    <>

                        <div className={'game-info-column-header'}>
                            {
                                gameStore.playerScoreThisRound ?
                                    <h2> Points earned: {gameStore.playerScoreThisRound}</h2> :
                                    <h2>Points: {advancedGameWindowStore.playerScore}/{advancedGameWindowStore.maxScore}</h2>
                            }
                        </div>
                        <div className={'game-info-column-items-container'}>
                            <div className={"unlock-items-container"}>
                                {
                                    (sessionStore.publicSession?.settings.allow_hints || sessionStore.publicSession?.settings.advanced_mode || gameStore.isSolo() || gameStore.isDle()) ?
                                        <>
                                            <h2>Hints</h2>
                                            <UnlockItemComponent pointCost={25}
                                                                 itemName={"poster"}
                                                                 formattedItemName={"Movie Poster"}
                                                                 onClick={onClick}
                                                                 isUnlocked={advancedGameWindowStore.posterUnlocked}/>

                                            <UnlockItemComponent itemName={'overview'}
                                                                 formattedItemName={'Plot Summary'}
                                                                 pointCost={25}
                                                                 onClick={onClick}
                                                                 isUnlocked={advancedGameWindowStore.overviewUnlocked}>
                                                {movieStore.movie?.overview}
                                            </UnlockItemComponent>

                                            <UnlockItemComponent itemName={"review"}
                                                                 formattedItemName={"User Review"}
                                                                 pointCost={50}
                                                                 onClick={onClick}
                                                                 isUnlocked={advancedGameWindowStore.reviewUnlocked}>
                                                <UserReviewWidget/>
                                            </UnlockItemComponent>
                                        </> : !sessionStore.publicSession?.settings.advanced_mode && <h2 className={"red"}>You're on your own</h2>
                                }
                            </div>
                            <div className={"guess-items-container"}>
                                {
                                    (sessionStore.publicSession?.settings.advanced_mode || gameStore.isSolo() || gameStore.isDle()) &&
                                    <>
                                        <h2>Name the cast</h2>
                                        <GuessCastCrewComponent formNumber={1}/>
                                        <GuessCastCrewComponent formNumber={2}/>
                                        <GuessCastCrewComponent formNumber={3}/>
                                        <GuessCastCrewComponent formNumber={4}/>
                                    </>
                                }
                        </div>
                    </div>
                </> :
                    <div className={"game-info-column-inactive"}>
                        <h2>Waiting for game to start...</h2>
                    </div>
            }
    </div>
    )
})