import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import './index.scss'
import {clsx} from "clsx";
import {GameInfoColumn} from "./gameInfoColumn";
import {LeaderboardColumn} from "./leaderboardColumn";
import rootStore from "../../../stores/RootStore";
import {PostRoundColumn} from "./PostRoundColumn";

export const GameInfoWindow = observer(() => {
    const {gameStore, movieStore, sessionStore, uiStore} = rootStore
    const [hasExpanded, setHasExpanded] = useState(false)

    const expandOnRender = (): boolean => {
        if(hasExpanded){
            return true
        } else {
            setHasExpanded(true)
            return false
        }
    }

    const shouldRender = () => {
        return (movieStore.movie &&
                (sessionStore.publicSession?.settings?.advanced_mode ||
                    sessionStore.publicSession?.settings?.allow_hints))
            || (movieStore.movie && gameStore.isDle() || (gameStore.isSolo() || gameStore.isHost() || gameStore.isGuest()))
    }

    const isRoundOver = () => {
        if((gameStore.isSolo() || gameStore.isDle()) && gameStore.playerScoreThisRound){
            return true
        } else return !!gameStore.playerGuessThisRound;
    }

    useEffect(() => {

    }, [movieStore.movie])

    return (
        <>
                { shouldRender() ?
                    <div className={clsx("game-info-window-container",
                        expandOnRender() && 'expanding',
                        uiStore.posterIsExpanded ? 'poster-expanded' : 'poster-not-expanded',
                        uiStore.mobileWindowFocus === 'info' && 'mobile-active')}>
                        <LeaderboardColumn/>
                        {isRoundOver() ? <PostRoundColumn/> : <GameInfoColumn/> }
                    </div>
                : null }

        </>
    );
})