import {action, makeAutoObservable} from "mobx";
import {RootStore} from "../RootStore";
import { Player } from "../../API";
import {
    createNewPlayer,
    createNewReservedPlayerName,
    getExistingPlayerByUserId,
    isPlayerNameAvailable, updatePlayerData
} from "../../api/graphql.tsx";
import {LazyPlayer} from "../../models";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {updatePlayer} from "../../graphql/mutations.ts";

export class PlayerStore {
    get player(): Player | LazyPlayer | undefined {
        return this._player;
    }

    setPlayer(value: Player | LazyPlayer | undefined) {
        this._player = value;
    }

    private _rootStore: RootStore;

    private _player?: Player | LazyPlayer | undefined
    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }

    @action
    async createPlayer(name: string, user_pool_id?: string, offline: boolean = false) {
        const isNameAvailable = await isPlayerNameAvailable(this._rootStore.authStore.accessToken?.toString(), name)
        if (isNameAvailable && user_pool_id && !offline) {
            const newPlayer = await createNewPlayer(this._rootStore.authStore.accessToken?.toString(),name.toLowerCase(), user_pool_id)
            if (newPlayer) {
                await createNewReservedPlayerName(this._rootStore.authStore.accessToken?.toString(), name)
                this.setPlayer(newPlayer)
            }
        } else if (offline) {
            const newPlayer = await createNewPlayer(this._rootStore.authStore.accessToken?.toString(), name.toLowerCase(), generateUniqueID())
            if (newPlayer) {
                this.setPlayer(newPlayer)
            }
        }
    }

    @action
    async loadPlayerFromUserId(id: string) {
        await getExistingPlayerByUserId(this._rootStore.authStore.accessToken?.toString(), id, this.setPlayer).catch()
    }

    @action
    async updatePlayerName(name: string){
        if(this.player){
            const newData = {...this.player, ...{name: name}} as Player
            await updatePlayerData(this._rootStore.authStore.accessToken?.toString(), newData)
            this.setPlayer(newData)
        }
    }

    @action
    async updatePlayerImage(path: string){
        if(this.player){
            const newData = {...this.player, ...{avatar_url: path}} as Player
            await updatePlayerData(this._rootStore.authStore.accessToken?.toString(), newData)
            this.setPlayer(newData)
        }
    }
}