import React from 'react';
import './index.scss'
import {MovieFilterForm} from "../../../components/movieFilterform";
import {observer} from "mobx-react";

export interface MovieFilterStepProps {
    onSubmit?: () => void
}

export const MovieFilterStep = observer((props: MovieFilterStepProps) => {
    return (
        <div className={"movie-filter-step-container"}>
            <MovieFilterForm onSubmit={props.onSubmit}/>
        </div>
    )
})