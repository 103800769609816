/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPlayerSessionConnection = /* GraphQL */ `mutation CreatePlayerSessionConnection(
  $input: CreatePlayerSessionConnectionInput!
  $condition: ModelPlayerSessionConnectionConditionInput
) {
  createPlayerSessionConnection(input: $input, condition: $condition) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerSessionConnectionMutationVariables,
  APITypes.CreatePlayerSessionConnectionMutation
>;
export const updatePlayerSessionConnection = /* GraphQL */ `mutation UpdatePlayerSessionConnection(
  $input: UpdatePlayerSessionConnectionInput!
  $condition: ModelPlayerSessionConnectionConditionInput
) {
  updatePlayerSessionConnection(input: $input, condition: $condition) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerSessionConnectionMutationVariables,
  APITypes.UpdatePlayerSessionConnectionMutation
>;
export const deletePlayerSessionConnection = /* GraphQL */ `mutation DeletePlayerSessionConnection(
  $input: DeletePlayerSessionConnectionInput!
  $condition: ModelPlayerSessionConnectionConditionInput
) {
  deletePlayerSessionConnection(input: $input, condition: $condition) {
    id
    session_code
    player_score
    player_name
    host_approved
    host_denied
    connection_is_closed
    ttl
    public_session_id
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlayerSessionConnectionMutationVariables,
  APITypes.DeletePlayerSessionConnectionMutation
>;
export const createAudienceGuess = /* GraphQL */ `mutation CreateAudienceGuess(
  $input: CreateAudienceGuessInput!
  $condition: ModelAudienceGuessConditionInput
) {
  createAudienceGuess(input: $input, condition: $condition) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAudienceGuessMutationVariables,
  APITypes.CreateAudienceGuessMutation
>;
export const updateAudienceGuess = /* GraphQL */ `mutation UpdateAudienceGuess(
  $input: UpdateAudienceGuessInput!
  $condition: ModelAudienceGuessConditionInput
) {
  updateAudienceGuess(input: $input, condition: $condition) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAudienceGuessMutationVariables,
  APITypes.UpdateAudienceGuessMutation
>;
export const deleteAudienceGuess = /* GraphQL */ `mutation DeleteAudienceGuess(
  $input: DeleteAudienceGuessInput!
  $condition: ModelAudienceGuessConditionInput
) {
  deleteAudienceGuess(input: $input, condition: $condition) {
    id
    value
    round_id
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAudienceGuessMutationVariables,
  APITypes.DeleteAudienceGuessMutation
>;
export const createAudienceSessionConnection = /* GraphQL */ `mutation CreateAudienceSessionConnection(
  $input: CreateAudienceSessionConnectionInput!
  $condition: ModelAudienceSessionConnectionConditionInput
) {
  createAudienceSessionConnection(input: $input, condition: $condition) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAudienceSessionConnectionMutationVariables,
  APITypes.CreateAudienceSessionConnectionMutation
>;
export const updateAudienceSessionConnection = /* GraphQL */ `mutation UpdateAudienceSessionConnection(
  $input: UpdateAudienceSessionConnectionInput!
  $condition: ModelAudienceSessionConnectionConditionInput
) {
  updateAudienceSessionConnection(input: $input, condition: $condition) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAudienceSessionConnectionMutationVariables,
  APITypes.UpdateAudienceSessionConnectionMutation
>;
export const deleteAudienceSessionConnection = /* GraphQL */ `mutation DeleteAudienceSessionConnection(
  $input: DeleteAudienceSessionConnectionInput!
  $condition: ModelAudienceSessionConnectionConditionInput
) {
  deleteAudienceSessionConnection(input: $input, condition: $condition) {
    id
    connection_is_closed
    roomCode
    host_approved
    ttl
    public_session_id_aud
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAudienceSessionConnectionMutationVariables,
  APITypes.DeleteAudienceSessionConnectionMutation
>;
export const createReservedPlayerName = /* GraphQL */ `mutation CreateReservedPlayerName(
  $input: CreateReservedPlayerNameInput!
  $condition: ModelReservedPlayerNameConditionInput
) {
  createReservedPlayerName(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservedPlayerNameMutationVariables,
  APITypes.CreateReservedPlayerNameMutation
>;
export const updateReservedPlayerName = /* GraphQL */ `mutation UpdateReservedPlayerName(
  $input: UpdateReservedPlayerNameInput!
  $condition: ModelReservedPlayerNameConditionInput
) {
  updateReservedPlayerName(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReservedPlayerNameMutationVariables,
  APITypes.UpdateReservedPlayerNameMutation
>;
export const deleteReservedPlayerName = /* GraphQL */ `mutation DeleteReservedPlayerName(
  $input: DeleteReservedPlayerNameInput!
  $condition: ModelReservedPlayerNameConditionInput
) {
  deleteReservedPlayerName(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservedPlayerNameMutationVariables,
  APITypes.DeleteReservedPlayerNameMutation
>;
export const createPublicSession = /* GraphQL */ `mutation CreatePublicSession(
  $input: CreatePublicSessionInput!
  $condition: ModelPublicSessionConditionInput
) {
  createPublicSession(input: $input, condition: $condition) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePublicSessionMutationVariables,
  APITypes.CreatePublicSessionMutation
>;
export const updatePublicSession = /* GraphQL */ `mutation UpdatePublicSession(
  $input: UpdatePublicSessionInput!
  $condition: ModelPublicSessionConditionInput
) {
  updatePublicSession(input: $input, condition: $condition) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePublicSessionMutationVariables,
  APITypes.UpdatePublicSessionMutation
>;
export const deletePublicSession = /* GraphQL */ `mutation DeletePublicSession(
  $input: DeletePublicSessionInput!
  $condition: ModelPublicSessionConditionInput
) {
  deletePublicSession(input: $input, condition: $condition) {
    id
    player_session_connection
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    current_round
    session_is_closed
    ttl
    connected_audience {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    connected_players {
      items {
        id
        session_code
        player_score
        player_name
        host_approved
        host_denied
        connection_is_closed
        ttl
        public_session_id
        avatar_url
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePublicSessionMutationVariables,
  APITypes.DeletePublicSessionMutation
>;
export const createMovieDle = /* GraphQL */ `mutation CreateMovieDle(
  $input: CreateMovieDleInput!
  $condition: ModelMovieDleConditionInput
) {
  createMovieDle(input: $input, condition: $condition) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMovieDleMutationVariables,
  APITypes.CreateMovieDleMutation
>;
export const updateMovieDle = /* GraphQL */ `mutation UpdateMovieDle(
  $input: UpdateMovieDleInput!
  $condition: ModelMovieDleConditionInput
) {
  updateMovieDle(input: $input, condition: $condition) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMovieDleMutationVariables,
  APITypes.UpdateMovieDleMutation
>;
export const deleteMovieDle = /* GraphQL */ `mutation DeleteMovieDle(
  $input: DeleteMovieDleInput!
  $condition: ModelMovieDleConditionInput
) {
  deleteMovieDle(input: $input, condition: $condition) {
    id
    movie_id
    end_at
    begin_at
    ttl
    review_id
    correct_score
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMovieDleMutationVariables,
  APITypes.DeleteMovieDleMutation
>;
export const createPlayer = /* GraphQL */ `mutation CreatePlayer(
  $input: CreatePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  createPlayer(input: $input, condition: $condition) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerMutationVariables,
  APITypes.CreatePlayerMutation
>;
export const updatePlayer = /* GraphQL */ `mutation UpdatePlayer(
  $input: UpdatePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  updatePlayer(input: $input, condition: $condition) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerMutationVariables,
  APITypes.UpdatePlayerMutation
>;
export const deletePlayer = /* GraphQL */ `mutation DeletePlayer(
  $input: DeletePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  deletePlayer(input: $input, condition: $condition) {
    id
    name
    score
    ttl
    user_pool_id
    daily_guesses
    average_accuracy
    avatar_url
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlayerMutationVariables,
  APITypes.DeletePlayerMutation
>;
export const createSessionRound = /* GraphQL */ `mutation CreateSessionRound(
  $input: CreateSessionRoundInput!
  $condition: ModelSessionRoundConditionInput
) {
  createSessionRound(input: $input, condition: $condition) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSessionRoundMutationVariables,
  APITypes.CreateSessionRoundMutation
>;
export const updateSessionRound = /* GraphQL */ `mutation UpdateSessionRound(
  $input: UpdateSessionRoundInput!
  $condition: ModelSessionRoundConditionInput
) {
  updateSessionRound(input: $input, condition: $condition) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSessionRoundMutationVariables,
  APITypes.UpdateSessionRoundMutation
>;
export const deleteSessionRound = /* GraphQL */ `mutation DeleteSessionRound(
  $input: DeleteSessionRoundInput!
  $condition: ModelSessionRoundConditionInput
) {
  deleteSessionRound(input: $input, condition: $condition) {
    id
    movie_id
    is_round_over
    round_number
    ttl
    public_session_id
    correct_answer
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSessionRoundMutationVariables,
  APITypes.DeleteSessionRoundMutation
>;
export const createPlayerGuess = /* GraphQL */ `mutation CreatePlayerGuess(
  $input: CreatePlayerGuessInput!
  $condition: ModelPlayerGuessConditionInput
) {
  createPlayerGuess(input: $input, condition: $condition) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerGuessMutationVariables,
  APITypes.CreatePlayerGuessMutation
>;
export const updatePlayerGuess = /* GraphQL */ `mutation UpdatePlayerGuess(
  $input: UpdatePlayerGuessInput!
  $condition: ModelPlayerGuessConditionInput
) {
  updatePlayerGuess(input: $input, condition: $condition) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerGuessMutationVariables,
  APITypes.UpdatePlayerGuessMutation
>;
export const deletePlayerGuess = /* GraphQL */ `mutation DeletePlayerGuess(
  $input: DeletePlayerGuessInput!
  $condition: ModelPlayerGuessConditionInput
) {
  deletePlayerGuess(input: $input, condition: $condition) {
    id
    guess_value
    player_name
    ttl
    public_session_id
    session_connection_id
    guess_data {
      poster_unlocked
      summary_unlocked
      review_unlocked
      correct_actor_guess_names
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlayerGuessMutationVariables,
  APITypes.DeletePlayerGuessMutation
>;
export const createMegacriticSession = /* GraphQL */ `mutation CreateMegacriticSession(
  $input: CreateMegacriticSessionInput!
  $condition: ModelMegacriticSessionConditionInput
) {
  createMegacriticSession(input: $input, condition: $condition) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMegacriticSessionMutationVariables,
  APITypes.CreateMegacriticSessionMutation
>;
export const updateMegacriticSession = /* GraphQL */ `mutation UpdateMegacriticSession(
  $input: UpdateMegacriticSessionInput!
  $condition: ModelMegacriticSessionConditionInput
) {
  updateMegacriticSession(input: $input, condition: $condition) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMegacriticSessionMutationVariables,
  APITypes.UpdateMegacriticSessionMutation
>;
export const deleteMegacriticSession = /* GraphQL */ `mutation DeleteMegacriticSession(
  $input: DeleteMegacriticSessionInput!
  $condition: ModelMegacriticSessionConditionInput
) {
  deleteMegacriticSession(input: $input, condition: $condition) {
    id
    code
    settings {
      max_players
      max_rounds
      advanced_mode
      time_limit
      allow_hints
      allow_image
      is_audience_session
      __typename
    }
    ttl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMegacriticSessionMutationVariables,
  APITypes.DeleteMegacriticSessionMutation
>;
