import React, { useState } from 'react'
import { observer } from 'mobx-react';
import {GameButton} from "../../components/buttons/GameButton.tsx";
import './index.scss'
import rootStore from "../../../stores/RootStore";
import {useNavigate} from "react-router";

export const JoiningPage = observer(() => {
    const [currentCode, setCurrentCode] = useState({value: "Room Code"})
    const navigate = useNavigate()
    const {sessionConnectionStore, gameStore} = rootStore
    const onFormSubmit = async (event: any) => {
        event.preventDefault()
        await sessionConnectionStore.connectToSession(currentCode.value)
        navigate('/game/guest-waiting')
    }
    const onRoomCodeChange = (event: any) => {
        event.preventDefault()
        if(currentCode.value === "Room Code"){
            setCurrentCode({value: ""})
        }
        setCurrentCode({value: event.target.value})
    }

    const onFocus = () => {
        if(currentCode.value === "Room Code"){
            setCurrentCode({value: ""})
        }
    }

    const onBlur = () => {
        if(currentCode.value === ""){
            setCurrentCode({value: "Room Code"})
        }
    }

    const onBackHomeClick = (event: any) => {
        event.preventDefault()
        gameStore.setGameState('inactive')
        navigate('/game/home')
    }

    return (
        <>
            <div className={'card-header'}>
                <h3>Enter your friend's private room code.</h3>
            </div>
        <div className="lobby-section-container">
            <input className="room-code-input" value={currentCode.value} onChange={onRoomCodeChange} onFocus={onFocus} onBlur={onBlur}/>
            <GameButton className="submit-button" type={'submit'} onClick={onFormSubmit}>
                Join Lobby
            </GameButton>
            <GameButton className="submit-button" onClick={onBackHomeClick}>
                {"Back"}
            </GameButton>
        </div>
        </>
    )
})