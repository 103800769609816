import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import rootStore, {RootStore} from "../../../stores/RootStore";
import './index.scss'
import {clsx} from "clsx";

export const UserReviewWidget = observer(() => {
    const {movieStore} = rootStore

    const getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const dayOfWeek = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayOfWeek}, ${month} ${dayOfMonth} ${year}`;
    }

    const getFormattedMovieReviewBody = (review: any) => {
        // Assuming review is a string with inline formatting like "_Title_" or "**Bold Text**"
        const reviewWithoutLinks = removeLinks(review)
        const formattedReview = reviewWithoutLinks
            .replace(/_(.*?)_/g, '<em>$1</em>') // Replace _Title_ with <em>Title</em>
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Replace **Bold Text** with <strong>Bold Text</strong>

        return <div dangerouslySetInnerHTML={{__html: formattedReview}} />
    }

    const removeLinks = (text: string) => {
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // Replace URLs with an empty string
        return text?.replace(urlRegex, '');
    }

    const getScoreColor = (score: number) => {
        if(score >= 8.0) {
            return 'green'
        } else if (score  >= 5.0 ){
            return 'yellow'
        } else if (score < 5.0) {
            return 'red';
        } else {
            return 'gray';
        }
    }
    const avatarPath = movieStore.movie?.reviews[0]?.author_details?.avatar_path ?
        movieStore.getTmdbImageUrlFromPathString(movieStore.movie?.reviews[0]?.author_details?.avatar_path) :
        '/profile1.jpg'


    return (
        <>
            <div className={'user-review-widget-container'}>
                <div className={'user-review-widget'}>
                    <div className={'user-review-header'}>
                        <div className={'user-review-header-content'}>
                            <div className={'user-avatar-container'}>
                                <img src={avatarPath} alt={'user-image'}/>
                            </div>
                            <div className={'name-and-date-container'}>
                                <div className={'user-name'}>
                                    {movieStore.movie?.reviews[0]?.author}
                                </div>
                                <div className={'review-date'}>
                                    {movieStore.movie?.reviews[0]?.created_at && getFormattedDate(movieStore.movie?.reviews[0]?.created_at)}
                                </div>
                            </div>
                            <div className={"review-score-container"}>
                                <div className={clsx('review-score', getScoreColor(rootStore.movieStore.movie?.reviews[0]?.author_details?.rating ?? 6))}>
                                    <h2>{movieStore.movie?.reviews[0]?.author_details?.rating ?? "N/A"}</h2>
                                </div>
                            </div>

                        </div>
                        <div className={'user-review-body'}>
                            <div className={'user-review-body-content'}>
                                {getFormattedMovieReviewBody(movieStore.movie?.reviews[0]?.content)}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})