import React from "react";
import './index.scss'
import { observer } from 'mobx-react'
import {GameButton} from "../../components/buttons/GameButton";
import {signInWithRedirect} from "aws-amplify/auth";
import rootStore from "../../../stores/RootStore";
import {Player} from "../../../models";
import {Navigate, useNavigate} from "react-router";

export const LoginPage = observer(() => {
    const navigate = useNavigate()
    const {authStore, playerStore} = rootStore
    const handlePlayOffline = async () => {
        playerStore.setPlayer({name: "guest", score: 0} as Player)
        navigate('/game/home')
    }

    return (
        <>
            {
                !authStore.currentUser ? <div className='home-page-container'>
                        <div className="card-header">
                            <h4>Login or begin an offline session.</h4>
                        </div>
                        <div className={"login-buttons-container"}>
                            <GameButton onClick={handlePlayOffline} className="play-offline-button" type='submit' soundOff={true}>
                                Play Offline
                            </GameButton>
                            <button onClick={() => signInWithRedirect({ provider: "Google"})} className="google-login-button" type='submit' >
                                <img src={"/google-signin.png"} alt={"google sign in button"}/>
                            </button>

                        </div>
                    </div> : playerStore.player ?
                        <Navigate replace={true} to={"/game/home"}/> :
                            <Navigate replace={true} to={"/game/create-player"}/>



            }
        </>
    )
})