import React from 'react'
import './index.scss'

export interface RainbowWaveProps {
    content: string
}

export const RainbowWave = (props: RainbowWaveProps) => {
    const {content} = props
    return (
        <ul className="c-rainbow animate__animated animate__zoomInDown">
            <li className="c-rainbow__layer c-rainbow__layer--white">{content}</li>
            <li className="c-rainbow__layer c-rainbow__layer--orange">{content}</li>
            <li className="c-rainbow__layer c-rainbow__layer--red">{content}</li>
            <li className="c-rainbow__layer c-rainbow__layer--violet">{content}</li>
            <li className="c-rainbow__layer c-rainbow__layer--blue">{content}</li>
            <li className="c-rainbow__layer c-rainbow__layer--green">{content}</li>
            <li className="c-rainbow__layer c-rainbow__layer--yellow">{content}</li>
        </ul>
    )
}