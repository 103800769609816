import React, {useEffect} from 'react'
import './index.scss'
import { observer } from 'mobx-react'
import rootStore from "../../../stores/RootStore";
import {GameButton} from "../../components/buttons/GameButton.tsx";
import {Navigate, useNavigate} from "react-router";

export const HostWaitingPage = observer(() => {
    const {gameStore, sessionStore} = rootStore
    const navigate = useNavigate()

    const onStartGame = (event: any) => {
        event.preventDefault()
        gameStore.hostStartNewRound().then(() => {
            navigate('/game/active')
        }).catch((err) => {
            console.log(JSON.stringify(err))
        })
    }

    useEffect(() => {
        const unloadCallback = (event: any) => {
            event.preventDefault();
            event.returnValue = "If you refresh or leave this session, you cannot rejoin.";
            return "If you refresh or leave this session, you cannot rejoin.";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
    
    return (
        <>
            {
                sessionStore.session ? <div className={"host-waiting-container"}>
                    <div className="room-code-container">
                        <div className="card-header host-waiting">
                            <h2 className="host-waiting-header">
                                Your room code
                            </h2>
                        </div>
                        <div className={"room-code-value-container"}>
                            <h3>"{sessionStore.session?.code}"</h3>
                        </div>
                        <div className="player-count-section">
                            <h4>Players: {sessionStore.sessionPlayers?.length ?? 0}</h4>
                        </div>
                        <GameButton onClick={onStartGame}
                                    disabled={sessionStore.sessionPlayers && sessionStore.sessionPlayers.length <= 1}>
                            Start
                        </GameButton>
                        <GameButton onClick={() => navigate('/game/home')}>Back</GameButton>
                    </div>
                </div> : <Navigate to={"/game/home"} replace={true}/>
            }
        </>

    )
})