import axios from "axios"
import {TmdbMovie, TmdbReview} from "../types/tmdb/movie"
import {convertObjectToArray} from "../util";

const bearer = 'Bearer ' + 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjZDUxMmZjMDQ3MzUzODc4OThjYzI0YjE4MDU2ZGI0ZCIsInN1YiI6IjY1YzMyMjVhOGMwYTQ4MDE4NDg1ZWVjZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.VIzN5SkHeyOlaso4w3kXrVPzshTvppfUyWXi5gebaTg'

export async function requestRandomMovie(filterString?: string): Promise<TmdbMovie> {
    const config = {
        headers: {
            Authorization: bearer,
            accept: 'application/json'
        }
    }
    let result: any
    while(!result) {
       const response = await axios.get((generateNewDiscoverUrl(filterString)), config)
            result = {...response.data.results[getRandomNumber(response.data.results.length)]}
            result = await enrichMovieWithReviewAndCastData(result)
    }


    return result
}

export async function getReviewsForMovie(id: number): Promise<TmdbReview[]> {
    const config = {
        headers: {
            Authorization: bearer,
            accept: 'application/json'
        }
    }
    let result: any

    await axios.get(generateGetReviewByMovieIdUrl(id), config).then((response) => {
        result = {...response.data.results}
    })
    return result
}

export async function getMovieById(id?: number): Promise<TmdbMovie | undefined> {
    let newMovie: TmdbMovie | undefined
        if(id){
            const config = {
                headers: {
                    Authorization: bearer,
                    accept: 'application/json'
                }
            }
            const response = await axios.get(generateGetByIdUrl(id), config)
            newMovie = response.data
            if(newMovie){
                const result = await enrichMovieWithReviewAndCastData(newMovie)
                if(result){
                    newMovie = result
                }

            }
        }
    return newMovie
}

export async function getReviewByReviewId(id: string): Promise<TmdbReview | undefined> {
    const config = {
        headers: {
            Authorization: bearer,
            accept: 'application/json'
        }
    }
    let result: any

    await axios.get(generateGetReviewByIdUrl(id), config).then((response) => {
        result = {...response.data}
    })
    return result
}

export async function getCastAndCrewByMovieId(id: number) {
        const config = {
        headers: {
            Authorization: bearer,
                accept: 'application/json'
        }
    }

    const result = await axios.get(generateGetCastByMovieId(id), config)
    return {...result.data}
}

export async function enrichMovieWithReviewAndCastData(movie: TmdbMovie): Promise<TmdbMovie | undefined> {
    if(movie.id){
        const result = await getCastAndCrewByMovieId(movie.id)


        const cast = result.cast
        const crew = result.crew
        if (movie.poster_path) {
            let movieReviews = await getReviewsForMovie(movie.id)
            const reviewObjectAsList = convertObjectToArray(movieReviews)
            if(reviewObjectAsList.length && reviewObjectAsList.length > 0){
                movie = {...movie, reviews: reviewObjectAsList, cast: cast, crew: crew}
            }

        }
        if (movie &&
            movie.poster_path &&
            movie.reviews &&
            movie.reviews.length &&
            movie.cast &&
            movie.cast.length > 0 &&
            movie.crew &&
            movie.crew.length > 0
        ){
            return movie
        }
    }
}

function generateNewDiscoverUrl(filterString? : string) {
    return "https://api.themoviedb.org/3/discover/movie?include_adult=false&include_video=false&language=en-US&sort_by=popularity.desc&vote_count.gte=250"
        + filterString + "&page=" + getRandomNumber(75)
}

function generateGetByIdUrl(id: number) {
    return 'https://api.themoviedb.org/3/movie/' + id + '?language=en-US'
}

function generateGetReviewByMovieIdUrl(movieId: number) {
    return 'https://api.themoviedb.org/3/movie/' + movieId + '/reviews?language=en-US&page=1'
}

function generateGetReviewByIdUrl(reviewId: string) {
    return 'https://api.themoviedb.org/3/review/' + reviewId
}

function getRandomNumber(val: number) {
    return (Math.floor(Math.random() * val) + 1)
}

function generateGetCastByMovieId(movieId: number) {
    return 'https://api.themoviedb.org/3/movie/' + movieId + '/credits?language=en-US'
}