import {RootStore} from "../RootStore";
import {action, makeAutoObservable} from "mobx";
import {
    MegacriticSession,
    PlayerSessionConnection,
    PublicSession,
    SessionSettingsInput
} from "../../API.ts";
import {
    createNewPlayerSessionConnection,
    createNewPublicSession,
    createNewSession,
    deleteExistingMegacriticSession,
    getExistingPublicSession,
    subscribeToNewPlayerSessionConnections,
    updateExistingPlayerSessionConnection,
    updateExistingPublicSession
} from "../../api/graphql.tsx";
import {delay} from "../../util";

export class SessionStore {
    get awaitingConnections(): boolean {
        return this._awaitingConnections;
    }

    @action
    setAwaitingConnections(value: boolean) {
        this._awaitingConnections = value;
    }
    get publicSession(): PublicSession | undefined {
        return this._publicSession;
    }

    @action
    setPublicSession(value: PublicSession | undefined) {
        this._publicSession = value;
    }
    get sessionPlayers(): (PlayerSessionConnection | undefined)[] | undefined {
        return this._sessionPlayers;
    }

    @action
    setSessionPlayers(value: (PlayerSessionConnection | undefined)[] | undefined) {
        if(value){
            this._sessionPlayers = value.sort((a, b) => (b?.player_score ?? 0) - (a?.player_score ?? 0))
        }

    }
    get session(): MegacriticSession | undefined {
        return this._session;
    }

    @action
    setSession(value: MegacriticSession | undefined) {
        this._session = value;
    }
    private _rootStore: RootStore;

    private _session?: MegacriticSession | undefined

    private _sessionPlayers?: (PlayerSessionConnection | undefined)[] | undefined

    private _awaitingConnections: boolean = false

    private _publicSession?: PublicSession | undefined
    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        // Initialize MobX auto-observable features
        makeAutoObservable(this, {}, {autoBind: true});
    }
    @action
    async createNewSession(roomCode: string, sessionSettings: SessionSettingsInput): Promise<void> {
        if(this._rootStore.playerStore.player?.id) {
            await createNewSession(this._rootStore.authStore.accessToken?.toString(),
                                    roomCode,
                                    sessionSettings,
                                    this.setSession,
                                    this._rootStore.subscriptionStore.addSubscription);
            await this.createNewPublicSession()
            this.setAwaitingConnections(true)
        }
    }

    @action
    async createNewPublicSession(){
        if(this.session && this._rootStore.playerStore.player) {
            await createNewPublicSession(this._rootStore.authStore.accessToken?.toString(),
                this.session.settings,
                this.setPublicSession,
                this._rootStore.gameStore.onNewPlayerGuess,
                this.handleSessionPlayersUpdate,
                this._rootStore.subscriptionStore.addSubscription)

            await this.hostCreateSessionConnection()

            subscribeToNewPlayerSessionConnections(this._rootStore.authStore.accessToken?.toString(),
                this.session.code,
                this.handleSessionPlayersUpdate)
        }
    }

    @action
    removePlayerSessionConnectionById(id: string) {
        const newPlayerList = this._sessionPlayers?.map((sessionConnection) => {
            if(sessionConnection && sessionConnection.id !== id) {
                return sessionConnection
            } else {
                this._rootStore.popupStore.setErrorPopup(sessionConnection?.player_name + " has left the lobby.")
            }
        })
        this.setSessionPlayers(newPlayerList)
    }

    @action
    async deleteCurrentSession(){
        if(this.session) {
            await deleteExistingMegacriticSession(this._rootStore.authStore.accessToken?.toString(), this.session).catch()
            if(this.publicSession) {
                const newPublicSessionData = this._publicSession
                if(newPublicSessionData) {
                    newPublicSessionData.session_is_closed = true
                    await updateExistingPublicSession(this._rootStore.authStore.accessToken?.toString(), newPublicSessionData).catch()
                    await this._rootStore.sessionConnectionStore.closeCurrentSessionConnection()
                }

            }
            this.setSession(undefined)
        }
    }

    @action
    async handleSessionPlayersUpdate(newSessionConnectionData: PlayerSessionConnection, newDataIsHost: boolean = false) {
        if (newDataIsHost) {
            // This part of the if statement should only run on the HOST CLIENT. The host must add themselves to the session player list manually.
            this._rootStore.sessionConnectionStore.setSessionConnection(newSessionConnectionData)
            this.updateSessionPlayerListWithNewPlayerSessionData(newSessionConnectionData)
            let newPublicSessionData = this.publicSession
            if (!!newPublicSessionData) {
                newPublicSessionData.player_session_connection?.push(newSessionConnectionData.id)
                await updateExistingPublicSession(this._rootStore.authStore.accessToken?.toString(), newPublicSessionData)
            }
        } else if (this.awaitingConnections) {
            // Wait for guest to finish subscribing to the new connection entity
            delay(750).then(() => {
                this.hostHandleConnectingPlayer(newSessionConnectionData)
            })
        } else {
            if(newSessionConnectionData.connection_is_closed){
                this.removePlayerSessionConnectionById(newSessionConnectionData.id)
            } else {
                this.updateSessionPlayerListWithNewPlayerSessionData(newSessionConnectionData)
            }

        }
    }

    @action
    async hostHandleConnectingPlayer(newSessionConnectionData: PlayerSessionConnection) {
        if(!newSessionConnectionData.host_approved && this.session &&
            (!this.sessionPlayers || this.sessionPlayers &&
                this.sessionPlayers.length < this.session.settings.max_players)) {
            let newPlayerSessionData = newSessionConnectionData
            newPlayerSessionData.public_session_id = this.publicSession?.id
            newPlayerSessionData.host_approved = true
            await updateExistingPlayerSessionConnection(this._rootStore.authStore.accessToken?.toString(), newPlayerSessionData)
            const newPublicSessionData = this.publicSession
            newPublicSessionData?.player_session_connection?.push(newPlayerSessionData.id)
            this.updateSessionPlayerListWithNewPlayerSessionData(newPlayerSessionData)
            if(newPublicSessionData){
                await updateExistingPublicSession(this._rootStore.authStore.accessToken?.toString(), newPublicSessionData)
            }
        }
    }

    @action
    async joinPublicSession(public_session_id: string){
        getExistingPublicSession(this._rootStore.authStore.accessToken?.toString(),
            public_session_id,
            this.setPublicSession,
            this._rootStore.gameStore.onNewPlayerGuess,
            this.handleSessionPlayersUpdate,
            this._rootStore.sessionRoundStore.onRoundCreate,
            this._rootStore.sessionConnectionStore.closeCurrentSessionConnection,
            this._rootStore.subscriptionStore.addSubscription).then(() => {
                if(this.publicSession){
                    if(this._rootStore.sessionConnectionStore.sessionConnection && this.publicSession.connected_players?.items.length){
                        let hasCurrentPlayerBeenAddedToSession = false
                        let newListOfPlayers = this.publicSession.connected_players.items.map((playerSessionConnection) => {
                            if(playerSessionConnection !== undefined && playerSessionConnection !== null){
                                if(playerSessionConnection.id === this._rootStore.sessionConnectionStore.sessionConnection?.id){
                                    hasCurrentPlayerBeenAddedToSession = true
                                }
                                return playerSessionConnection
                            }

                        })
                        if(!hasCurrentPlayerBeenAddedToSession){
                            newListOfPlayers.push(this._rootStore.sessionConnectionStore.sessionConnection)
                        }
                        if (newListOfPlayers) {
                            // @ts-ignore
                            this.setSessionPlayers(newListOfPlayers)
                        }
                        this._rootStore.gameStore.setGameState('guest')
                    }
                }
            }).catch()

    }

    @action
    resetCurrentSessionData(){
        this.setSessionPlayers([])
        this.setSession(undefined)
        this.setPublicSession(undefined)
        this.setAwaitingConnections(false)
    }

    private isPlayerSessionConnectionValidatedByHost(newSessionConnectionData: PlayerSessionConnection): boolean {
        return !!this.publicSession?.player_session_connection?.some((validatedConnectionId) => {
            if(validatedConnectionId === newSessionConnectionData.id) {
                return true
            }
        })
    }

    private updateSessionPlayerListWithNewPlayerSessionData(newSessionConnectionData: PlayerSessionConnection){
        let isUpdated = false
        const newPlayerSessionConnectionList = this.sessionPlayers?.map((playerSession) => {
            if(playerSession?.id === newSessionConnectionData.id){
                isUpdated = true
                return newSessionConnectionData
            } else {
                return playerSession
            }
        })
        if(!isUpdated && this.sessionPlayers && this.sessionPlayers.length > 0){
            this._sessionPlayers?.push(newSessionConnectionData)
        }else if(isUpdated){
            this.setSessionPlayers(newPlayerSessionConnectionList)
        } else {
            this.setSessionPlayers([newSessionConnectionData])
        }
    }

    public onPublicSessionClosed() {
        // This should not be called directly. Call sessionConnectionStore.closeCurrentSessionConnection instead
        this.setPublicSession(undefined)
        this._rootStore.gameStore.resetPlayerGuesses()
        this._rootStore.sessionRoundStore.resetStore()
        this._rootStore.sessionConnectionStore.resetStore()
        this._rootStore.subscriptionStore.resetCurrentSessionSubs()
        this._rootStore.gameStore.setGameState("inactive")
    }

    private async hostCreateSessionConnection() {
        if(this._rootStore.playerStore.player && this.session?.code) {
            this._rootStore.sessionConnectionStore.setIsHost(true)
            await createNewPlayerSessionConnection(this._rootStore.authStore.accessToken?.toString(),
                this._rootStore.playerStore.player,
                this.session?.code,
                true,
                this._rootStore.sessionStore.handleSessionPlayersUpdate,
                this.publicSession?.id,
                this._rootStore.subscriptionStore.addSubscription)
        }
    }


}